import React, {useState, useEffect, useCallback} from  'react';
import { withRouter } from 'react-router-dom';
import {netstat} from '../config';
import {connection, did, vc} from 'mui-metablockchain-sdk';

function VC(props) {

	// const getVC = useCallback ( async () => {
	// 	let provider = await connection.buildConnection(netstat);
	// 	//const names = await localStorage.getItem('did')
	// 	const names = 'did:yidindji:xocep'
	// 	const ownDid = await did.sanitiseDid(names)
	// 	console.log(ownDid)
	// 	const vcIds = await vc.getVCIdsByDID(ownDid, provider);
	// 	console.log(vcIds);
	// 	if(vcIds.length === 0) {
	// 		setCurrentVC(null);
	// 	} else {
	// 		setCurrentVC(vcIds[0]);
	// 	}
	// }, [] );

	// useEffect(() => {
	// 	getVC();
	// }, [getVC]);

	return (
		<div className = "card" style = {{marginTop: "130px"}}>
			<div className = "card-body">
				<div className = "row text-center">
					<div className = "col">
						<h2 className = "pt-1">
							Manage Digital Currency
						</h2>
					</div>
				</div>
				<div className = "row justify-content-center">
					<div className = "col-6 pt-4 d-grid">
						<button className = "btn btn-block btn-lg btn-outline-primary"
							onClick = {() => props.history.push("/vc/create-new-vc")}
						>
							Create New Currency
						</button>
					</div>
				</div>
				<div className = "row justify-content-center">
					<div className = "col-6 pt-4 d-grid">
						<button className = "btn btn-block btn-lg btn-outline-primary"
							onClick = {() => props.history.push("/vc/all-vc")}
						>
							View All VC
						</button>
					</div>
				</div>
				<div className = "row justify-content-center">
					<div className = "col-6 pt-4 d-grid">
						<button className = "btn btn-block btn-lg btn-outline-primary"
							onClick = {() => props.history.push("/vc/my-vc")}
						>
							My Currency
						</button>
					</div>
				</div>
			</div>
		</div>
	);
}
export default withRouter(VC);