/*
Switch between production and test environment by modifying this flag
set isDev = true for test environment
set isDev = false to prod environment
IMP : Logout before changing the environment
*/

const REACT_APP_PRESIGNED_API_URL_DEV =
  "https://ssid.metamui.money/devnet/getKycPresignedURL";
const REACT_APP_PRESIGNED_API_URL_PROD =
  "https://ssid.metablock.exchange/prod/getKycPresignedURL";
const REACT_APP_TOKEN_VC_PRESIGNED_API_URL_DEV =
  "https://ssid.metamui.money/devnet/generatePresignedS3AdminUrl";
const REACT_APP_TOKEN_VC_PRESIGNED_API_URL_PROD =
  "https://ssid.metablock.exchange/prod/generatePresignedS3AdminUrl";
const REACT_APP_SUPPORT_TICKET_BUCKET_NAME_DEV = "support-ticket";
const REACT_APP_SUPPORT_TICKET_BUCKET_NAME_PROD = "support-ticket-prod";
const REACT_APP_KYC_BUCKET_NAME_DEV = "user-kyc-document";
const REACT_APP_KYC_BUCKET_NAME_PROD = "user-kyc-document-prod";
const REACT_APP_TOKEN_VC_BUCKET_NAME_DEV = "ssid-token-vc";
const REACT_APP_TOKEN_VC_BUCKET_NAME_PROD = "ssid-token-vc-prod";
const REACT_APP_PERMITTED_USER_GROUP = "kyc_admins";
const REACT_APP_PAIRWISE_API_DEV = "https://socket.metamui.money";
const REACT_APP_PAIRWISE_API_PROD = "https://socket.metamui.id";
const REACT_APP_PAIRWISE_WEBRTC_DEV = "wss://socket.metamui.money/ws/";
const REACT_APP_PAIRWISE_WEBRTC_DEV_2 =
  "wss://didcomm.metamui.money/ws/connect/";
const REACT_APP_PAIRWISE_WEBRTC_PROD = "wss://socket.metamui.id/ws/";
const REACT_APP_PAIRWISE_WEBRTC_PROD_2 = "https://didcomm.metamui.id/ws/connect/";
const REACT_APP_BASE_URL_DEV = "https://ssid.metamui.money/devnet/";
const REACT_APP_BASE_URL_PROD = "https://ssid.metablock.exchange/prod/";
const REACT_APP_DID_RESTORE_BUCKET_NAME_DEV = "ssid-did-restore";
const REACT_APP_DID_RESTORE_BUCKET_NAME_PROD = "ssid-did-restore-prod";
const REACT_APP_ADMIN_TOOL_DID_PROD = "did:ssid:pairwise_admin";
const REACT_APP_ADMIN_TOOL_DID_DEV = "did:ssid:v3ssid-admin";

let isDev = process.env.REACT_APP_isDev === "true";

const AWS_COGNITO_CONFIG = (_) =>
  isDev
    ? require(process.env.REACT_APP_AWS_COGNITO_CONFIG_DEV)
    : require(process.env.REACT_APP_AWS_COGNITO_CONFIG_PROD);

// const AWS_DB_CONFIG = _=> (isDev ? require(REACT_APP_AWS_DB_CONFIG_DEV) : require(REACT_APP_AWS_DB_CONFIG_PROD));

const PRESIGNED_API_URL = isDev
  ? REACT_APP_PRESIGNED_API_URL_DEV
  : REACT_APP_PRESIGNED_API_URL_PROD;
const BASE_URL = isDev ? REACT_APP_BASE_URL_DEV : REACT_APP_BASE_URL_PROD;
const TOKEN_VC_PRESIGNED_API_URL = isDev
  ? REACT_APP_TOKEN_VC_PRESIGNED_API_URL_DEV
  : REACT_APP_TOKEN_VC_PRESIGNED_API_URL_PROD;

const SUPPORT_TICKET_BUCKET_NAME = isDev
  ? REACT_APP_SUPPORT_TICKET_BUCKET_NAME_DEV
  : REACT_APP_SUPPORT_TICKET_BUCKET_NAME_PROD;
const KYC_BUCKET_NAME = isDev
  ? REACT_APP_KYC_BUCKET_NAME_DEV
  : REACT_APP_KYC_BUCKET_NAME_PROD;
const TOKEN_VC_BUCKET_NAME = isDev
  ? REACT_APP_TOKEN_VC_BUCKET_NAME_DEV
  : REACT_APP_TOKEN_VC_BUCKET_NAME_PROD;

const DID_RESTORE_BUCKET_NAME = isDev
  ? REACT_APP_DID_RESTORE_BUCKET_NAME_DEV
  : REACT_APP_DID_RESTORE_BUCKET_NAME_PROD;

const DID_ADMIN_TOOL = isDev
  ? REACT_APP_ADMIN_TOOL_DID_DEV
  : REACT_APP_ADMIN_TOOL_DID_PROD;

const PERMITTED_USER_GROUP = REACT_APP_PERMITTED_USER_GROUP;

//Api key and url for firebase

const API_KEY_FIREBASE = process.env.REACT_APP_API_KEY_FIREBASE;
const FIREBASE_URL = isDev
  ? process.env.REACT_APP_FIREBASE_URL_DEV
  : process.env.REACT_APP_FIREBASE_URL_PROD;

const PAIRWISE_API = isDev
  ? REACT_APP_PAIRWISE_API_DEV
  : REACT_APP_PAIRWISE_API_PROD;
const PAIRWISE_WEBRTC = isDev
  ? REACT_APP_PAIRWISE_WEBRTC_DEV
  : REACT_APP_PAIRWISE_WEBRTC_PROD;
const PAIRWISE_WEBRTC_V2 = isDev
  ? REACT_APP_PAIRWISE_WEBRTC_DEV_2
  : REACT_APP_PAIRWISE_WEBRTC_PROD_2;
const netstat = isDev ? "dev" : "mainnet";
const KYC_STATUS_STRING = {
  A: "Approved",
  D: "Declined",
  R: "In Review",
  S: "Submitted",
};

const SUPPORT_TICKET_STATUS = {
  open: "Opened",
  close: "Closed",
  review: "In Review",
};

const APIS = isDev
  ? "https://v3explorer.metamui.money/api/v1/"
  : "https://scan.metablockchain.id/api/v1/";

module.exports = {
  isDev,
  // AWS_DB_CONFIG,
  AWS_COGNITO_CONFIG,
  KYC_STATUS_STRING,
  PRESIGNED_API_URL,
  PERMITTED_USER_GROUP,
  SUPPORT_TICKET_BUCKET_NAME,
  KYC_BUCKET_NAME,
  SUPPORT_TICKET_STATUS,
  API_KEY_FIREBASE,
  FIREBASE_URL,
  PAIRWISE_API,
  PAIRWISE_WEBRTC,
  netstat,
  APIS,
  TOKEN_VC_BUCKET_NAME,
  TOKEN_VC_PRESIGNED_API_URL,
  BASE_URL,
  DID_RESTORE_BUCKET_NAME,
  DID_ADMIN_TOOL,
  PAIRWISE_WEBRTC_V2,
};
