import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import styled from "styled-components";
import metamui_logo1 from "../Assets/icons/swn_logo.png";
import "../styles/Sidemenu_style.css";

const IdCard = (props) => {
  return (
    <>
      <Card className="card_id_div" style={id_card}>
        <WaterMark>
          <img className="logo_id_div" src={metamui_logo1} alt="" />
          <Card.Body>
            <h3
              className="text-center"
              style={{ color: "#1240c2", fontWeight: "600" }}
            >
              MetaMUI
            </h3>
            {/* <h6 className="text-center">TRIBAL IDENTITY CARD</h6> */}
            <RedBar />
            <Row>
              <Col sm="4" md="4">
                <img
                  className="user_image_div"
                  style={user_image}
                  src={props.img}
                  alt=""
                />
              </Col>
              <Col sm="4" md="8">
                <UserTextData>
                  <h4>
                    Name :
                    <b>
                      {props.data.first_name
                        ? props.data.first_name + " " + props.data.last_name
                        : props.data.first_name + " " + props.data.last_name}
                    </b>
                  </h4>
                  <br />
                  <Row>
                    <Col>
                      <p>
                        {props.data.email ? "Email : " : "Phone : "}
                        <b>
                          {props.data.email
                            ? props.data.email
                            : props.data.phone_number}
                        </b>
                      </p>
                      <p>
                        DOB : <b>{props.data.dob}</b>
                      </p>
                    </Col>
                    {/* <Col>
                      <p>Gender: </p>
                      <p>DOE: </p>
                    </Col> */}
                  </Row>
                  <br />
                  <h4>DID : {props.data.hasOwnProperty('did_identifier') ? props.data.did_identifier : "DID is pending"}</h4>
                </UserTextData>
              </Col>
            </Row>
            <RedBarSmall />
            {/* <p className="quote_div" style={{ fontSize: "12.5px" }}>
              THIS INSTRUMENT REMAINS THE PROPERTY OF THE SOVEREIGN YIDINDJI
              GOVERNMENT
            </p> */}
            <RedBar />
          </Card.Body>
        </WaterMark>
      </Card>
    </>
  );
};
export default IdCard;

const UserTextData = styled.div`
  > p {
    font-size: 15px;
    font-weight: 600;
  }
  @media screen and (min-width: 360px) and (max-width: 575px) {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    > h4 {
    }
  }
  @media screen and (min-width: 576px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
    margin-left: -4rem;
    margin-top: 6rem;
    width: 15rem;
  }
`;
const RedBar = styled.div`
  background: #1240c2;
  height: 10px;
  margin-bottom: 10px;
`;
const RedBarSmall = styled.div`
  background: #699eff;
  height: 5px;
  margin-top: 10px;
`;
const WaterMark = styled.div`
  > img {
    position: absolute;
    height: 220px;
    z-index: -10;
    top: 15%;
    left: 50%;
    opacity: 0.3;
  }
`;
const id_card = {
  left: "50%",
  transform: "translate(-50%)",
  width: "600px",
  height: "100%",
  border: "1px solid #699EFF",
  borderRadius: "25px",
  padding: "10px",
  background: "rgba(18, 64, 194, .1)",
};
const user_image = {
  height: "150px",
  width: "150px",
};
