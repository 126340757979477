import React, { useState, useEffect } from "react";
import { Button, Table, Nav, Navbar, Container } from "react-bootstrap";
import AddUser from "../components/AddUsers";
import UpdateUser from "../components/UpdateUser";
import UserDataService from "../services/API_urls";
import Loader from "react-loader-spinner";
import "../styles/Sidemenu_style.css";

function SubAdmin(props) {
  // state = {
  //   users: [],
  //   isLoading: true,
  //   modalShow: false,
  //   updateModel: false,
  //   updateValue: [],
  // };
  // componentDidMount() {
  //   this.retrieveUsers();
  // }

  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalShow, setModalShow] = useState(false);
  const [updateModel, setUpdateModel] = useState(false);
  const [updateValue, setUpdateValue] = useState([]);

  useEffect(() => {
    retrieveUsers();
  }, [])

  const retrieveUsers = () => {
    UserDataService.getAll()
      .then((response) => {
        // this.setState({ users: response.data, isLoading: false });
        setUsers(response.data);
        setIsLoading(false);
        console.log(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const updateTable = (e) => {
    // this.setState({ updateModel: true, updateValue: e });
    setUpdateModel(true);
    setUpdateValue(e);
  };

  const deleteTable = (e) => {
    UserDataService.remove(e.id)
      .then((response) => {
        console.log(response);
        retrieveUsers();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  window.scrollTo({ top: 0 });
  return (
    <>
      <div>
        <Navbar
          variant="light"
          style={{
            marginTop: "10%",
            marginBottom: "30px",
            marginRight: "50px",
          }}
        >
          <Container className="container1_div">
            <Navbar.Brand
              href="#home"
              style={{
                color: "#fff",
                fontWeight: "600",
                fontSize: "1.5rem",
                padding: "10px",
                width: "10rem",
                border: "none",
                textAlign: "center",
                background: "#1240C2",
                borderRadius: "15px",
                cursor: "pointer",
              }}
            >
              Admins
            </Navbar.Brand>
            <Nav className="me-auto">
              <Nav.Link
                onClick={() => setModalShow(true)}
                style={{
                  color: "#fff",
                  fontWeight: "600",
                  fontSize: "1.5rem",
                  padding: "10px",
                  border: "none",
                  textAlign: "center",
                  background: "#1240C2",
                  borderRadius: "15px",
                  cursor: "pointer",
                }}
              >
                {" "}
                Create New Admin
              </Nav.Link>
              {/* <Nav.Link href="#features">Features</Nav.Link>
              <Nav.Link href="#pricing">Pricing</Nav.Link> */}
            </Nav>
          </Container>
        </Navbar>
      </div>
      <Table>
        <thead>
          <tr></tr>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Number</th>
            <th>Look Up</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading &&
            users.map((e, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{e.name}</td>
                <td>{e.email}</td>
                <td>{e.number}</td>
                <td style={{ display: "flex", flexDirection: "column" }}>
                  <Button
                    // variant="info"
                    style={{
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: "#1240C2",
                    }}
                    onClick={() => updateTable(e)}
                  >
                    <b>EDIT</b>
                  </Button>
                  &nbsp;&nbsp;
                  <Button
                    // variant="danger"
                    style={{
                      border: "none",
                      borderRadius: "10px",
                      backgroundColor: "#C42E2E",
                    }}
                    onClick={() => deleteTable(e)}
                  >
                    <b>DELETE</b>
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
      <div style={{ "margin-left": "30%" }}>
        {isLoading && <Loader type="ThreeDots" color="black" />}
        <AddUser
          show={modalShow}
          onHide={() => setModalShow(false)}
          func={retrieveUsers}
        />
        {updateModel && (
          <UpdateUser
            show={updateModel}
            onHide={() => setUpdateModel(false)}
            func={retrieveUsers}
            data={updateValue}
          />
        )}
      </div>
    </>
  );
}

export default SubAdmin;
