import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { collective, connection, did } from "mui-metablockchain-sdk";
import "../styles/Sidemenu_style.css";
import "../styles/Drop_style.css";
// import metamui_logo from "../Assets/icons/METAMUI Logo.png";
import metamui_logo1 from "../Assets/icons/swn_logo.png";
// import search_icon from "../Assets/icons/Search.png";
// import bellIcon from "../Assets/icons/bellIcon.png";
import auth from "../services/loginService";
import {GetCall} from "../services/KycService";
import { Auth } from "aws-amplify";
import styled from "styled-components";
// import Search from "./Search";
import { BASE_URL, netstat } from "../config";
// import PairwiseStatus from './PairwiseStatus';

const tokens = localStorage.getItem("login");

function SudoLink() {
  const [isSudo, setIsSudo] = useState(false);
  useEffect(() => {
    let loc_did = localStorage.getItem("did");
    const checkSudoStatus = async () => {
      const api = await connection.buildConnection(netstat);
      const lastHeader = await api.rpc.chain.getHeader();
      const blockNumber = lastHeader.number;
      if (loc_did) {
        const accId = await did.resolveDIDToAccount({identifier: loc_did, blockNumber: blockNumber}, api);
        if (accId) {
          const key = (await api.query.sudo.key()).toHuman();
          if (key) {
            if (key === accId) {
              setIsSudo(true);
            }
          }
        }
      }
    };
    checkSudoStatus();
  }, []);
  return (
    <>
      {isSudo && (
        <NavLink to="/sudo-actions" className="nav_link">
          {" "}
          <i className="bx bx-file nav_icon"></i>{" "}
          <span className="nav_name">Sudo Council</span>{" "}
        </NavLink>
      )}
    </>
  );
}

function SideBar(props) {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     name: localStorage.getItem("did") || "Admin",
  //     transfer_access: false,
  //     token: null,
  //     searchData: null,
  //     noData: false,
  //   };
  // }

  const [name, setName] = useState(localStorage.getItem("did") || "Admin");
  const [transfer_access, setTransfer_access] = useState(false);
  const [token, setToken] = useState(null);
  const [searchData, setSearchData] = useState(null);
  const [noData, setNoData] = useState(false);

  function search(key) {
    console.log(key);
    fetch(`${BASE_URL}GetCall` + key).then((data) => {
      data.json().then((resp) => {
        console.log("resp", resp);
        if (resp.length > 0) {
          // this.setState({ searchData: resp, noData: false });
          setSearchData(resp);
          setNoData(false);
        } else {
          // this.setState({ noData: true, searchData: null });
          setNoData(true);
          setSearchData(null);
        }
      });
    });
  }

  // async componentDidMount() {
  //   const { attributes } = await Auth.currentAuthenticatedUser();
  //   console.log("SideBar.....", attributes);
  //   if (attributes.name) {
  //     this.setState({ name: attributes.name });
  //   } else {
  //     var email = attributes.email;
  //     var username =
  //       email.charAt(0).toUpperCase() +
  //       email.substring(1, email.lastIndexOf("@"));
  //     this.setState({ name: username });
  //   }

  //   const ssid_name = localStorage.getItem("did");
  //   const token = localStorage.getItem("login");
  //   if (ssid_name) {
  //     this.setState({ name: ssid_name, transfer_access: true, token: token });
  //     console.log("meeks", this.state.transfer_access);
  //   }
  // }

  useEffect(() => {
    const fetchData = async () => {
      // const { attributes } = await Auth.currentAuthenticatedUser();
      // console.log("SideBar.....", attributes);

      // if (attributes.name) {
      //   setName(attributes.name);
      // } else {
      //   var email = attributes.email;
      //   // console.log(attributes.Auth);
      //   var username =
      //     email.charAt(0).toUpperCase() +
      //     email.substring(1, email.lastIndexOf("@"));
      //   setName(username);
      // }
      // console.log(username);
      let name;
    try {
      const resp = await GetCall("getAdminDetails");
      if(resp.hasOwnProperty('message')) {
        if(resp.message.firstName && resp.message.lastName) {
          name = resp.message.firstName + " " + resp.message.lastName;
        } else {
          name = "Fetching name failed";
        }
        setName(name);
      }
    } catch (e) {
      name = "Fetching name failed";
      setName("Fetching name failed");
    }
      const ssid_name = localStorage.getItem("did");
      const token = localStorage.getItem("login");
      if (ssid_name) {
        setName(name);
        setTransfer_access(true);
        setToken(token);
        console.log("meeks", transfer_access);
      }
    };
    fetchData();
  }, [transfer_access]);

  // state = {
  //   open: false,
  // };

  const [open, setOpen] = useState(false);

  const handleButtonClick = () => {
    setOpen(!open);
  };

  return name ? (
    <>
      <header className="header" id="header">
        <div className="header_toggle">
          {" "}
          <i className="bx bx-menu" id="header-toggle"></i>
        </div>
        {/* <div className="header_item"> <h5 style={{color:'white'}}>Welcome Admin</h5> </div> */}

        <div className="header_item">
          <Link to="/" className="nav-logo">
            <img className="img_logo" src={metamui_logo1} alt="" />
          </Link>
          <span className="nav_logo-name">MetaMUI</span>
          <span className="welcome_name">
            <h5>
              <b>Hi {name === "Fetching name failed" ? " " : name}</b> <br />
              <span style={{ color: "gray" }}>Welcome back!</span>
            </h5>
          </span>
        </div>
        {/* <Search /> */}

        {/* <div className="nav_input-name">
          <span>
            <input
              className="input_search"
              type="text"
              placeholder="Search"
              onChange={(event) => this.search(event.target.value)}
            />
            <div>
              {this.state.searchData ? (
                <div>
                  {this.state.searchData.map((item, i) => (
                    <div> {item.name}</div>
                  ))}
                </div>
              ) : (
                ""
              )}
              {this.state.noData ? <h6>No data found </h6> : null}
            </div>
            <img
              className="search_logo"
              src={search_icon}
              alt="Search"
              style={{
                height: "20px",
                width: "auto",
              }}
            />
          </span>
        </div> */}

        {/* <NewModal /> */}

        {/* <div className="nav_logo-name">
            <span>
              <img className="bell_icon" src={bellIcon} alt="" />
            </span>
          </div> */}
      </header>

      <div className="l-navbar" id="nav-bar">
        <nav className="nav" style={{ overflowY: "scroll" }}>
          <div>
            {/* <NavLink to="/" className="nav_logo"> <img className="img_logo" src={yidindji_logo} alt="" />
            <span className="nav_logo-name">Yidindji</span> </NavLink> */}
            <div className="nav_list">
              {" "}
              <NavLink to="/" className="nav_link active">
                {" "}
                <i className="bx bx-grid-alt nav_icon"></i>
                <span className="nav_name">KYC Dashboard</span>{" "}
              </NavLink>
              {/* <NavLink to="/subusers" className="nav_link">
                  {" "}
                  <i className="bx bx-user nav_icon"></i>{" "}
                  <span className="nav_name">Sub Admin Users</span>{" "}
                </NavLink>
                <NavLink to="/support_ticket" className="nav_link"> 
                <i class='bx bx-message-square-detail nav_icon'></i> <span class="nav_name">Support Tickets</span> </NavLink>  */}
              <NavLink to="/restore_vc_request" className="nav_link">
                {" "}
                <i className="bx bx-bookmark nav_icon"></i>{" "}
                <span className="nav_name">Restore VC</span>{" "}
              </NavLink>
              <NavLink to="/btc" className="nav_link">
                {" "}
                <i className="bx bx-file nav_icon"></i>{" "}
                <span className="nav_name">BTC</span>{" "}
              </NavLink>
              <NavLink to="/vc" className="nav_link">
                {" "}
                <i className="bx bx-id-card nav_icon"></i>{" "}
                <span className="nav_name">CBDC</span>{" "}
              </NavLink>
              <NavLink to="/public_did_details" className="nav_link">
                {" "}
                <i className="bx bx-id-card nav_icon"></i>{" "}
                <span className="nav_name">Public DID</span>{" "}
              </NavLink>
              {/* <NavLink to="/sudo-actions" className="nav_link">
                {" "}
                <i className="bx bx-file nav_icon"></i>{" "}
                <span className="nav_name">Sudo Actions</span>{" "}
              </NavLink> */}
              <NavLink to="/events" className="nav_link">
                {" "}
                <i className="bx bx-list-ul nav_icon"></i>{" "}
                <span className="nav_name">Events</span>{" "}
              </NavLink>
              {/* <Drop>
                <button
                  type="button"
                  className="nav_link"
                  onClick={this.handleButtonClick}
                >
                  <i className="bx bx-user nav_icon"></i>
                  <span className="nav_name">User Registration</span>
                </button>
                {this.state.open && (
                  <Dropdown>
                    <ul>
                      <li>
                        {" "}
                        <Link
                          to="/usersignup"
                          className="up-nav"
                          onClick={this.handleButtonClick}
                        >
                          {" "}
                          Sign Up
                        </Link>
                      </li>
                      <li>
                        <Link
                          to="/usersignin"
                          className="in-nav"
                          onClick={this.handleButtonClick}
                        >
                          {" "}
                          Sign In
                        </Link>
                      </li>
                      <li>
                        <Link to="/otpscreen" className="in-nav">
                          {" "}
                          OTP
                        </Link>
                      </li>
                    </ul>
                  </Dropdown>
                )}
              </Drop> */}
              {tokens !== null && (
                <>
                  <NavLink to={`/transfer/${tokens}`} className="nav_link">
                    {" "}
                    <i className="bx bx-send nav_icon"></i>{" "}
                    <span className="nav_name">Transfer</span>{" "}
                  </NavLink>
                  <NavLink to={`/transaction/${tokens}`} className="nav_link">
                    {" "}
                    <i className="bx bx-book nav_icon"></i>{" "}
                    <span className="nav_name">Transaction</span>{" "}
                  </NavLink>

                  <NavLink to={`/create-token/${tokens}`} className="nav_link">
                    {" "}
                    <i className="bx bx-send nav_icon"></i>{" "}
                    <span className="nav_name">Create Token</span>{" "}
                  </NavLink>
                  <NavLink to={`/token-list/${tokens}`} className="nav_link">
                    {" "}
                    <i className="bx bx-book nav_icon"></i>{" "}
                    <span className="nav_name">Token List</span>{" "}
                  </NavLink>
                  <SudoLink />
                  <NavLink to="/manage-admins" className="nav_link mb-0 pb-0">
                    {" "}
                    <i className="bx bx-send nav_icon"></i>{" "}
                    <span className="nav_name">Manage Admins</span>{" "}
                  </NavLink>
                </>
              )}
              {/* <div className = "row">
                <div className = "col py-2">
                  <PairwiseStatus />
                </div>
              </div> */}
              <NavLink
                to="/login"
                onClick={() => {
                  localStorage.removeItem("login");
                  localStorage.removeItem("did");
                  auth.logout();
                  window.location = "/login";
                }}
                className="nav_link"
              >
                {" "}
                <i className="bx bx-log-out nav_icon"></i>{" "}
                <span className="nav_name">Sign Out</span>{" "}
              </NavLink>
            </div>
          </div>{" "}
        </nav>
      </div>
    </>
  ) : (
    <h1> navbar</h1>
  );
}

export default SideBar;
const Drop = styled.div`
  position: relative;
  margin-left: 0.6rem;
  > button {
    padding: 11px;
    margin-top: 15px;
    border: none;
    border-radius: 15px;
    background-color: #fff;
    color: black;
    cursor: pointer;
    outline: 0;
    font-size: 1rem;
    font-weight: 600;
    > button:hover {
      background-color: #e5f6ef;
    }
  }
`;
const Dropdown = styled.div`
  position: absolute;
  width: 10rem;
  height: 5rem;
  z-index: 2;
  border-radius: 1rem;
  margin-left: 1.2rem;
  margin-top: -1rem;
  border: 1px solid rgba(0, 0, 0, 0.04);
  background-color: #1240c2;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14);
  > ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    margin-top: 0.5rem;
  }
  > li {
    padding: 8px 12px;
  }
  > li:hover {
    cursor: pointer;
  }
`;
