import React, { useState } from "react";
import BTCSettingsChangeVC from "../components/BTCSettingsChangeVC";
import BTCSettingsCreatedByYou from "../components/BTCSettingsCreatedByYou";
import BTCVCCard from "./BTCVCCard";

function BTCSettings(props) {
  const [subC, setSubC] = useState("settingsLanding");

  function backDefault() {
    setSubC("settingsLanding");
  }

  function subComponent() {
    if (subC === "settingsLanding") {
      return (
        <div className="vh-100">
          <BTCVCCard />
          <div className="row justify-content-center">
            <div className="col-6 pt-4 btc-block ">
              <button
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={() => setSubC("settingsChangeVC")}
              >
                Change VC
              </button>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-6 pt-4 btc-block">
              <button
                className="btn btn-outline-primary btn-block btn-lg"
                onClick={() => setSubC("")}
              >
                Certificates Created By You
              </button>
            </div>
          </div>
        </div>
      );
    } else if (subC === "settingsChangeVC") {
      return <BTCSettingsChangeVC back={backDefault} />;
    } else return <BTCSettingsCreatedByYou back={backDefault} />;
  }
  return (
    <div className="container-fluid card" style={{ marginTop: "150px" }}>
      <div className="card-body">
        <div className="row justify-content-between bg-light">
          <div className="col">
            <h4 className="py-2">Settings</h4>
          </div>
          <div className="col-2 py-2">
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => props.changePath("/btc")}
            >
              Back To Main
            </button>
          </div>
        </div>
        {subComponent()}
      </div>
    </div>
  );
}

export default BTCSettings;
