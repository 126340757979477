import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { connection, did } from "mui-metablockchain-sdk";
import Swal from "sweetalert2";
import { netstat } from "../../config";
import { FormControl } from "react-bootstrap";

function BTCForm(props) {
  const [listItems, setListItems] = useState([]);
  const [userInput, setUserInput] = useState(null);
  const [pages, setPages] = useState("FirstPage");
  const [formResult, setFormResult] = useState(null);
  const [t, setT] = useState(true);
  const [isValid, setIsValid] = useState("");
  const [rI, setRi] = useState(true);
  const [empty, setEmpty] = useState();

  const addDid = async () => {
    let cI = listItems;
    if (userInput) {
      let provider = await connection.buildConnection(netstat);
      let sponserDID = await did.resolveDIDToAccount({identifier: userInput}, provider);
      if (!sponserDID) {
        setIsValid("false");
      } else {
        cI.push(userInput);
        setListItems(cI);
        setIsValid("true");
      }
    }
    setRi(!rI);
    setUserInput(" ");
  };

  function removeSponserSignatories(index) {
    const newArr = listItems;
    newArr.splice(index, 1);
    setListItems(newArr);
    setT(!t);
  }
  const onSubmit = async (values) => {
    setFormResult({ ...values, sponsorSignatories: listItems });
    setPages("SecondPage");
  };
  const resolve = async (data) => {
    let provider = await connection.buildConnection(netstat);
    let convertName = await did.resolveDIDToAccount({identifier: data.exchangeTo}, provider);
    if (!convertName) {
      console.log("Not a valid did");
      return { exchangeTo: "Not a valid did" };
    }
  };

  function getListMap() {
    return (
      <>
        {listItems.map((item, index) => (
          <div className="mt-2 border-bottom" key={index + 1}>
            <div className="row justify-content-between">
              <div className="col-9 mb-2 text-center">{item}</div>
              {/* {console.log("Before onclick" + listItems)} */}
              <div className="col-3 mb-2">
                <span
                  className="btn btn-outline-danger btn-block"
                  onClick={() => removeSponserSignatories(index) && listItems}
                >
                  Remove
                </span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }

  return (
    <>
      {pages === "FirstPage" && (
        <div className="card my-5 bg-light">
          <div className="card-body">
            <div className="row justify-content-center mb-3 text-center">
              <div className="col-6">
                <h4>BTC Form</h4>
              </div>
            </div>
            <Form
              onSubmit={onSubmit}
              initialValues={
                formResult
                  ? {
                      ...formResult,
                    }
                  : {
                      amount: "",
                      exchangeTo: "",
                      serialid: "",
                      primarySignatory: props.myDid,
                    }
              }
              validate={(values) => {
                const errors = {};
                if (!values.amount) {
                  errors.amount = "Amount cannot be blank";
                } else if (isNaN(values.amount)) {
                  errors.amount = "Must be a number";
                }
                if (!values.exchangeTo) {
                  errors.exchangeTo = "Recepient Address cannot be blank";
                }
                if (!values.serialid) {
                  errors.serialid = "serial Id cannot be blank";
                }
                return Object.keys(errors).length ? errors : resolve(values);
              }}
              render={({
                handleSubmit,
                form,
                submitting,
                pristine,
                values,
              }) => (
                <form onSubmit={handleSubmit}>
                  <div>
                    <Field name="primarySignatory">
                      {({ input, meta }) => (
                        <div className="mb-3">
                          <label
                            htmlFor="primarySignatory"
                            className="form-label"
                          >
                            Primary Signatory
                          </label>
                          <input
                            {...input}
                            type="text"
                            className="form-control"
                            id="primarySignatory"
                            readOnly
                            disabled
                          />
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="amount">
                      {({ input, meta }) => (
                        <div className="mb-3">
                          <label htmlFor="amount" className="form-label">
                            Enter Amount
                          </label>
                          <input
                            {...input}
                            type="number"
                            pattern="[0-9]"
                            className={
                              meta.touched
                                ? meta.error
                                  ? "form-control is-invalid"
                                  : "form-control is-valid"
                                : "form-control"
                            }
                            id="amount"
                            // value={formData.amount}
                            placeholder="Enter Amount"
                          />
                          {meta.error && meta.touched && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                          {!meta.error && meta.touched && (
                            <div className="valid-feedback">Looks Good!</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="serialid">
                      {({ input, meta }) => (
                        <div className="mb-3">
                          <label htmlFor="serialid" className="form-label">
                            Serial Id
                          </label>
                          <input
                            {...input}
                            type="text"
                            className={
                              meta.touched
                                ? meta.error
                                  ? "form-control is-invalid"
                                  : "form-control is-valid"
                                : "form-control"
                            }
                            id="serialid"
                            placeholder="Enter some text to describe this transaction"
                          />
                          {meta.error && meta.touched && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                          {!meta.error && meta.touched && (
                            <div className="valid-feedback">Looks Good!</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="exchangeTo">
                      {({ input, meta }) => (
                        <div className="mb-3">
                          <label htmlFor="exchangeTo" className="form-label">
                            Enter Recepient Address
                          </label>
                          <input
                            {...input}
                            type="text"
                            className={
                              meta.touched
                                ? meta.error
                                  ? "form-control is-invalid"
                                  : "form-control is-valid"
                                : "form-control"
                            }
                            id="exchangeTo"
                            placeholder="Enter DID to which this will be minted"
                          />
                          {meta.error && meta.touched && (
                            <div className="invalid-feedback">{meta.error}</div>
                          )}
                          {!meta.error && meta.touched && (
                            <div className="valid-feedback">Looks Good!</div>
                          )}
                        </div>
                      )}
                    </Field>
                  </div>
                  <div className="mb-3" name="sponserSignatories">
                    <div className="accordion" id="sponsorAccordion">
                      <div className="accordion-item">
                        <h5 className="accordion-header" id="sponsorHeader">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#sponsorSignatoriesList"
                          >
                            Enter Sponsor Signatories
                          </button>
                        </h5>
                        <div
                          id="sponsorSignatoriesList"
                          className="accordion-collapse collapse show"
                          aria-labelledby="sponsorHeader"
                        >
                          <div className="accordion-body">
                            <div className="row">
                              <div className="col-9">
                                <input
                                  className={
                                    userInput === null
                                      ? "form-control"
                                      : isValid === "true"
                                      ? "form-control is-valid"
                                      : isValid === "false"
                                      ? "form-control is-invalid"
                                      : "form-control"
                                  }
                                  id="validDid"
                                  placeholder="Enter a valid did"
                                  onChange={(e) => setUserInput(e.target.value)}
                                />
                              </div>
                              <div className="col-3">
                                <span
                                  className="btn btn-primary btn-block"
                                  onClick={(e) => {
                                    addDid();
                                  }}
                                >
                                  Add
                                </span>
                              </div>
                            </div>
                            {isValid === "false" && (
                              <div className="text-danger">
                                <small>Invalid did!</small>
                              </div>
                            )}
                            {isValid === "true" && (
                              <div className="text-success">
                                <small>Looks Good!</small>
                              </div>
                            )}

                            {getListMap()}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    <div className="col-3 mt-3 ">
                      <button
                        className="btn btn-outline-primary btn-block "
                        type="submit"
                        disabled={submitting}
                      >
                        Proceed
                      </button>
                    </div>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      )}
      {pages === "SecondPage" && (
        <div className="card" style={{ marginTop: "90px" }}>
          <div className="card-header text-center">
            <h3 className="mb-2">Check Data</h3>
            <h5>Primary Signatory: {formResult.primarySignatory}</h5>
          </div>
          <div className="row justify-content-center pb-5">
            <div className=" col-8">
              <table className="table table-bordered table-striped">
                <thead>
                  <tr>
                    <th scope="col">Amount</th>
                    <th scope="col">Serial ID</th>
                    <th scope="col">Recepient Address</th>
                    <th scope="col">Sponsors Signatories</th>
                  </tr>
                </thead>
                <tbody>
                  {formResult && (
                    <tr>
                      <td>{formResult.amount}</td>
                      <td>{formResult.serialid}</td>
                      <td>{formResult.exchangeTo}</td>
                      <td>
                        {listItems &&
                          listItems.map((e, i) => (
                            <span key={i}>{e}&emsp;</span>
                          ))}
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
              <div className="row justify-content-evenly">
                <div className="col-4 mt-3">
                  <button
                    className="btn btn-warning btn-block"
                    onClick={() => setPages("FirstPage")}
                  >
                    Edit
                  </button>
                </div>
                <div className="col-4 mt-3">
                  <button
                    className="btn btn-success btn-block"
                    onClick={() => {
                      setPages("FirstPage");
                      props.submitForm(formResult);
                      setFormResult(null);
                      setUserInput(undefined);
                    }}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BTCForm;
