import React from "react";
// import BTCVCCard from "./BTCVCCard";

function BTCLanding(props) {
  return (
    <div className="card" style={{ marginTop: "130px" }}>
      <div className="card-body">
        <div className="row text-center">
          <div className="col">
            <h2 className="pt-1">
              Welcome To Blockchain Tradable Certificates
            </h2>
            {/* <BTCVCCard /> */}
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-4 pt-4">
            <button
              className="btn btn-lg btn-outline-primary btn-block"
              onClick={() => props.changePath("/btc/create-new-instrument")}
            >
              Create New Certificate
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BTCLanding;
