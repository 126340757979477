import React, { useState, useEffect, useRef } from "react";
import { VCList, getDID, UpdateVC } from "../services/vcService";
import TokenAdminVC from "./VCTypes/TokenAdminVC";
import UndecodedVC from "./VCTypes/UndecodedVC";
import { utils, token, connection } from "mui-metablockchain-sdk";
import { PAIRWISE_API, netstat, PAIRWISE_WEBRTC } from "../config";
import Swal from "sweetalert2";
import "../styles/vc_style.css";

function VCAnalytics(props) {
  const [filter, setFilter] = useState("");
  const [list, setList] = useState(null);
  const [selectedVC, setSelectedVC] = useState(null);
  const [renderList, setRenderList] = useState(null);
  const [preSearchList, setPreSearchList] = useState(null);
  const [currPage, setCurrPage] = useState(1);
  const [msgStat, setMsgStat] = useState(0);
  const [decodedObj, setDecodedObj] = useState(null);
  const [myDID, setMyDID] = useState(null);
  const webSocket = useRef(null);
  const [socketMessages, setSocketMessages] = useState([]);
  const [search, setSearch] = useState(null);

  useEffect(() => {
    webSocket.current = new WebSocket(PAIRWISE_WEBRTC);

    webSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("okst:", data);

      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = () => {
      webSocket.current.close();
    };
    return () => webSocket.current.close();
  }, []);

  useEffect(() => {
    let data = socketMessages.pop();
    console.log("okst2:", data);

    if (data) {
      switch (data.type) {
        case "connect":
          checkLoginStatus();
          break;
        case "leave":
          console.log(
            "webrtc connection closed because the other peer leave the data channel..."
          );
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [socketMessages]);
  const checkLoginStatus = () => {
    send({
      type: "login",
      name: "did:ssid:v3ssid-admin",
    });
  };

  useEffect(() => {
    let res = getDID();
    res.then(function (v) {
      if (Array.isArray(v)) {
        setMyDID(v[0].did);
      } else {
        setMyDID(v);
      }
    });
  }, []);

  useEffect(() => {
    decodeObj();
  }, []);
  console.log("incoming props:", props.idToken);
  const decodeObj = async () => {
    let api = await connection.buildConnection(netstat);
    let tokenList = await token.getTokenList(api);
    setDecodedObj(tokenList);
  };

  useEffect(() => {
    if (filter.length === 0) {
      setFilter("All");
    }
    if (msgStat === 1) {
      setMsgStat(0);
    }
  }, [filter, msgStat]);

  function sortByDate(a, b) {
    if (new Date(a.timestamp).getTime() < new Date(b.timestamp).getTime()) {
      return 1;
    }
    if (new Date(a.timestamp).getTime() > new Date(b.timestamp).getTime()) {
      return -1;
    }
    return 0;
  }

  useEffect(() => {
    let res = VCList(
      "handleTokenVC",
      JSON.stringify({ action: "get_request" })
    );
    res.then(function (v) {
      if (Array.isArray(v)) {
        let sorted = v.sort(sortByDate);
        setList(sorted);
      } else {
        setMsgStat(1);
      }
    });
  }, []);
  useEffect(() => {
    if (list) {
      if (filter === "All") {
        setRenderList(list);
        setPreSearchList(list);
      } else if (filter === "Active") {
        const activeVc = list.filter(
          (e) => e.hasOwnProperty("vc_status") && e.vc_status.includes("Active")
        );

        setRenderList(activeVc);
        setPreSearchList(activeVc);
      } else if (filter === "Inactive") {
        const inActiveVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_status") && e.vc_status.includes("Inactive")
        );

        setRenderList(inActiveVc);
        setPreSearchList(inActiveVc);
      } else if (filter === "Pending") {
        const pendingVc = list.filter(
          (e) =>
            e.hasOwnProperty("request_status") && e.request_status.includes("P")
        );

        setRenderList(pendingVc);
        setPreSearchList(pendingVc);
      } else if (filter === "Accepted") {
        const acceptedVc = list.filter(
          (e) =>
            e.hasOwnProperty("request_status") && e.request_status.includes("A")
        );

        setRenderList(acceptedVc);
        setPreSearchList(acceptedVc);
      } else if (filter === "Cancelled") {
        const cancelledVc = list.filter(
          (e) =>
            e.hasOwnProperty("request_status") && e.request_status.includes("C")
        );

        setRenderList(cancelledVc);
        setPreSearchList(cancelledVc);
      } else if (filter === "Declined") {
        const declinedVc = list.filter(
          (e) =>
            e.hasOwnProperty("request_status") && e.request_status.includes("D")
        );

        setRenderList(declinedVc);
        setPreSearchList(declinedVc);
      } else if (filter === "In-Review") {
        const inReviewVc = list.filter(
          (e) =>
            e.hasOwnProperty("request_status") && e.request_status.includes("R")
        );

        setRenderList(inReviewVc);
        setPreSearchList(inReviewVc);
      } else if (filter === "Token VC") {
        const tokenVc = list.filter(
          (e) => e.hasOwnProperty("vc_type") && e.vc_type.includes("TokenVC")
        );

        setRenderList(tokenVc);
        setPreSearchList(tokenVc);
      } else if (filter === "Slash Token VC") {
        const SlashtokenVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") && e.vc_type.includes("SlashTokens")
        );

        setRenderList(SlashtokenVc);
        setPreSearchList(SlashtokenVc);
      } else if (filter === "Mint Token VC") {
        const MinttokenVc = list.filter(
          (e) => e.hasOwnProperty("vc_type") && e.vc_type.includes("MintTokens")
        );

        setRenderList(MinttokenVc);
        setPreSearchList(MinttokenVc);
      } else if (filter === "Token Transfer VC") {
        const TokenTransferVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") && e.vc_type.includes("TokenTransferVC")
        );

        setRenderList(TokenTransferVc);
        setPreSearchList(TokenTransferVc);
      } else if (filter === "Inactive Token Transfer VC") {
        const InactiveTokenTransferVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") &&
            e.vc_type.includes("TokenTransferVC") &&
            e.hasOwnProperty("vc_status") &&
            e.vc_status.includes("Inactive")
        );

        setRenderList(InactiveTokenTransferVc);
        setPreSearchList(InactiveTokenTransferVc);
      } else if (filter === "Active Token Transfer VC") {
        const ActiveTokenTransferVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") &&
            e.vc_type.includes("TokenTransferVC") &&
            e.hasOwnProperty("vc_status") &&
            e.vc_status.includes("Active")
        );

        setRenderList(ActiveTokenTransferVc);
        setPreSearchList(ActiveTokenTransferVc);
      } else if (filter === "Inactive Mint Token VC") {
        const InactiveMintTokenVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") &&
            e.vc_type.includes("MintTokens") &&
            e.hasOwnProperty("vc_status") &&
            e.vc_status.includes("Inactive")
        );

        setRenderList(InactiveMintTokenVc);
        setPreSearchList(InactiveMintTokenVc);
      } else if (filter === "Active Mint Token VC") {
        const ActiveMintTokenVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") &&
            e.vc_type.includes("MintTokens") &&
            e.hasOwnProperty("vc_status") &&
            e.vc_status.includes("Active")
        );

        setRenderList(ActiveMintTokenVc);
        setPreSearchList(ActiveMintTokenVc);
      } else if (filter === "Inactive Slash Token VC") {
        const InactiveSlashTokenVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") &&
            e.vc_type.includes("SlashTokens") &&
            e.hasOwnProperty("vc_status") &&
            e.vc_status.includes("Inactive")
        );

        setRenderList(InactiveSlashTokenVc);
        setPreSearchList(InactiveSlashTokenVc);
      } else if (filter === "Active Slash Token VC") {
        const ActiveSlashTokenVc = list.filter(
          (e) =>
            e.hasOwnProperty("vc_type") &&
            e.vc_type.includes("SlashTokens") &&
            e.hasOwnProperty("vc_status") &&
            e.vc_status.includes("Active")
        );

        setRenderList(ActiveSlashTokenVc);
        setPreSearchList(ActiveSlashTokenVc);
      }
    }
  }, [filter, list]);

  useEffect(() => {
    if (preSearchList !== null && search !== null) {
      let newList = [];
      for (let i = 0; i < preSearchList.length; ++i) {
        if (preSearchList[i].hasOwnProperty("token_name")) {
          let tName = preSearchList[i].token_name;
          if (tName !== null) {
            if (
              tName
                .replaceAll(" ", "")
                .toLowerCase()
                .includes(search.replaceAll(" ", "").toLowerCase()) &&
              tName
                .replaceAll(" ", "")
                .toLowerCase()
                .startsWith(search.charAt(0).toLowerCase())
            ) {
              newList.push(preSearchList[i]);
            }
          }
        } else if (preSearchList[i].hasOwnProperty("currency_code")) {
          if (decodedObj !== null) {
            for (let j = 0; j < decodedObj.length; ++j) {
              if (
                decodedObj[j].currencyCode === preSearchList[i].currency_code
              ) {
                if (
                  decodedObj[j].name
                    .replaceAll(" ", "")
                    .toLowerCase()
                    .includes(search.replaceAll(" ", "").toLowerCase()) &&
                  decodedObj[j].name
                    .replaceAll(" ", "")
                    .toLowerCase()
                    .startsWith(search.charAt(0).toLowerCase())
                ) {
                  newList.push(preSearchList[i]);
                }
              }
            }
          }
        }
      }
      setRenderList(newList);
    }
  }, [search, preSearchList]);

  // function dropdown() {
  //   return (
  //     <div className="dropdown">
  //       <button
  //         className="btn btn-secondary dropdown-toggle"
  //         type="button"
  //         id="filterDropdown"
  //         data-bs-toggle="dropdown"
  //         aria-expanded="false"
  //       >
  //         {filter}
  //       </button>
  //       <ul className="dropdown-menu ul_div" aria-labelledby="filterDropdown">
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("All")}
  //           >
  //             All VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Active")}
  //           >
  //           Active Slash/Mint/Transfer VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Inactive")}
  //           >
  //            Inactive Slash/Mint/Transfer VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Pending")}
  //           >
  //             Pending Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Accepted")}
  //           >
  //             Accepted Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Declined")}
  //           >
  //             Declined Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Cancelled")}
  //           >
  //             Cancelled Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("In-Review")}
  //           >
  //             In-Review Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Token VC")}
  //           >
  //             Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Slash Token VC")}
  //           >
  //             Slash Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Mint Token VC")}
  //           >
  //             Mint Token VC
  //           </button>
  //         </li>
  //         <li>
  //           <button
  //             className="dropdown-item"
  //             type="button"
  //             onClick={() => setFilter("Token Transfer VC")}
  //           >
  //             Token Transfer VC
  //           </button>
  //         </li>
  //       </ul>
  //     </div>
  //   );
  // }

  const deletionRequest = async (tokenData) => {
    let data = {
      sender: myDID,
      source: "ssid",
      type: "token_deletion",
      tokenData: tokenData,
    };
    send({
      type: "transfer-request",
      data: data,
      token: props.idToken,
    });
  };

  const deletionRequestCouncil = async (tokenData) => {
    let res = UpdateVC("handleTokenVC", {
      status: "TDR",
      current_proposal: "token_deletion",
      request_id: list[selectedVC].request_id,
    });
    // let data = {
    //   sender: myDID,
    //   source: "ssid",
    //   type: "token_deletion_council",
    //   tokenData: tokenData,
    // };
    // send({
    //   type: "transfer-request",
    //   data: data,
    //   token: props.idToken,
    // });
  };

  function statusType(value) {
    if (value.request_status === "P") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#cf9823",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Pending</span>
        </React.Fragment>
      );
    } else if (value.request_status === "C") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#cf2323",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Cancelled</span>
        </React.Fragment>
      );
    } else if (value.request_status === "S") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#23c6cf",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Submitted</span>
        </React.Fragment>
      );
    } else if (value.request_status === "A") {
      if (value.hasOwnProperty("vc_id")) {
        return (
          <React.Fragment>
            <i
              style={{
                height: "1rem",
                width: "1rem",
                backgroundColor: "#23cf26",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></i>
            <span>&nbsp;Accepted</span>
          </React.Fragment>
        );
      } else {
        return (
          <React.Fragment>
            <i
              style={{
                height: "1rem",
                width: "1rem",
                backgroundColor: "#ffff4d",
                borderRadius: "50%",
                display: "inline-block",
              }}
            ></i>
            <span>&nbsp;In-Review</span>
          </React.Fragment>
        );
      }
    } else if (value.request_status === "D") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#2323cf",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;Declined</span>
        </React.Fragment>
      );
    } else if (value.request_status === "R") {
      return (
        <React.Fragment>
          <i
            style={{
              height: "1rem",
              width: "1rem",
              backgroundColor: "#ffff4d",
              borderRadius: "50%",
              display: "inline-block",
            }}
          ></i>
          <span>&nbsp;In-Review</span>
        </React.Fragment>
      );
    } else if (value.hasOwnProperty("vc_status")) {
      return value.vc_status;
    } else {
      return "Status Unavailable";
    }
  }

  function getOwner(value) {
    if (value.hasOwnProperty("owner_did")) {
      return value.owner_did;
    } else if (value.hasOwnProperty("token_owner")) {
      return value.token_owner;
    } else if (
      value.hasOwnProperty("submitter_did") &&
      !value.hasOwnProperty("owner_did")
    ) {
      return value.submitter_did;
    } else return "No Owner/Test Data";
  }

  function getVCType(item) {
    if (item.hasOwnProperty("vc_type")) {
      return item.vc_type;
    } else return "TokenVC";
  }

  function getTokenName(obj) {
    if (obj.hasOwnProperty("token_name")) {
      return obj.token_name;
    } else if (obj.hasOwnProperty("currency_code")) {
      if (decodedObj !== null) {
        for (let i = 0; i < decodedObj.length; ++i) {
          if (decodedObj[i].currencyCode === obj.currency_code) {
            return decodedObj[i].name;
          }
        }
      } else return "Resolving Currency ID";
    } else return "Cannot Resolve Name";
  }

  function getIndex(item) {
    for (let i = 0; i < list.length; ++i) {
      if (
        list[i].hasOwnProperty("request_id") &&
        item.hasOwnProperty("request_id")
      ) {
        if (list[i].request_id === item.request_id) {
          console.log(i);
          return i;
        }
      }
    }
  }

  function getRow() {
    if (renderList !== null && Array.isArray(renderList)) {
      return renderList.map((item, index) => (
        <tr key={index}>
          <th scope="row">{index + 1}</th>
          <td>{getTokenName(item)}</td>
          <td>{statusType(item)}</td>
          <td>{getOwner(item)}</td>
          <td>{getVCType(item)}</td>
          <td>
            <button
              className="btn btn-primary btn-sm"
              onClick={() => {
                setSelectedVC(getIndex(item));
                setCurrPage(2);
              }}
            >
              View Details
            </button>
          </td>
        </tr>
      ));
    } else {
      if (msgStat === 0) {
        return (
          <React.Fragment>
            <tr>
              <td>
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
                <span>Fetching From Api</span>
              </td>
            </tr>
          </React.Fragment>
        );
      } else if (msgStat === 1) {
        return (
          <React.Fragment>
            <tr>
              <td>
                <span>Something Went Wrong</span>
              </td>
            </tr>
          </React.Fragment>
        );
      }
    }
  }

  const issueTokens = async (obj, amount, issueDID) => {
    let myArr = [obj];
    Object.assign(myArr[0], { vcDID: issueDID, Amount: amount });
    let names = myDID;
    send({
      type: "vc-create",
      name: names,
      source: "ssid",
      token: null,
      package: {
        myArr,
        DID: myDID,
      },
    });
    setCurrPage(1);
  };

  const setReservable = async (value) => {
    let res = UpdateVC("handleTokenVC", {
      reservable_fund: value,
      status: "R",
      request_id: list[selectedVC].request_id,
      current_proposal: "token_creation",
    });
    res.then(function (v) {
      if (v.hasOwnProperty("message")) {
        let msg = v.message;
        Swal.fire(
          "This currency creation request is now ready to be submitted. Proceed from SSID App to Approve for council voting"
        );
      }
    });
    let myArr = [list[selectedVC]];
    myArr[0].request_status = "R";
    Object.assign(myArr[0], {
      Amount: value,
      request_status: "R",
      submitter_did: myDID,
      type: "TokenProposal",
    });
    let data = {
      myArr,
      source: "ssid",
      resbal: value,
      token: value,
      sender: myDID,
    };
    send({
      type: "vc-request",
      source: "ssid",
      data: data,
      token: props.idToken,
    });
    setCurrPage(1);
  };

  function getDetails() {
    if (list !== null) {
      let obj = list[selectedVC];
      if (obj.hasOwnProperty("vc_type")) {
        if (obj.vc_type === "TokenVC") {
          return (
            <TokenAdminVC
              vc={obj}
              submitForm={submitForm}
              myDID={myDID}
              setReservable={setReservable}
              issueTokens={issueTokens}
              deletionRequest={deletionRequest}
              deletionRequestCouncil={deletionRequestCouncil}
            />
          );
        } else {
          return <UndecodedVC vc={obj} vcAction={vcAction} myDID={myDID} />;
        }
      } else if (obj.hasOwnProperty("request_status")) {
        return (
          <TokenAdminVC
            vc={obj}
            vcAction={vcAction}
            myDID={myDID}
            setReservable={setReservable}
            issueTokens={issueTokens}
            deletionRequest={deletionRequest}
            deletionRequestCouncil={deletionRequestCouncil}
          />
        );
      } else {
        return <UndecodedVC vc={obj} vcAction={vcAction} myDID={myDID} />;
      }
    } else {
      return (
        <React.Fragment>
          <tr>
            <td>Nothing to show</td>
          </tr>
        </React.Fragment>
      );
    }
  }

  const send = (data) => {
    webSocket.current.send(JSON.stringify(data));
  };

  const submitForm = async (input) => {
    console.log(input);
    let myArr = [list[selectedVC]];
    // myArr[0].request_status = "R";
    Object.assign(myArr[0], {
      vcDID: input.vcDID,
      Amount: input.Amount,
      request_status: "R",
      issuerList: input.issuerList,
    });
    let names = myDID;
    send({
      type: "vc-create",
      name: names,
      source: "ssid",
      token: input.amount,
      package: {
        myArr,
        DID: input.vcDID,
      },
    });
    setCurrPage(1);
  };

  const vcAction = async (obj, action, did) => {
    let myArr = [obj];
    if (action !== "Transfer") {
      Object.assign(myArr[0], { Action: action });
    } else {
      Object.assign(myArr[0], { vcDID: did, Action: action });
    }
    const names = myDID;
    send({
      type: "vc-action",
      name: names,
      source: "ssid",
      token: null,
      package: {
        myArr,
        DID: did,
      },
    });
    setCurrPage(1);
  };

  function activepage() {
    if (currPage === 1) {
      return (
        <div className="mt-3">
          <table className="table">
            <thead>
              <tr className="table-primary">
                <th scope="col">S.No</th>
                <th scope="col">Token Name</th>
                <th scope="col">Status</th>
                <th scope="col">Owner DID</th>
                <th scope="col">VC Type</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>{getRow()}</tbody>
          </table>
        </div>
      );
    } else {
      return <React.Fragment>{getDetails()}</React.Fragment>;
    }
  }

  return (
    <div className="container-fluid" style={{ marginTop: "120px" }}>
      <div className="row justify-content-between bg-light">
        <div className="col">
          <h4 className="py-2">{filter}</h4>
        </div>
      </div>
      <div className="row">
        <div className="col-2 mb-1">
          <button
            className={
              filter === "All"
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              setFilter("All");
              setCurrPage(1);
            }}
          >
            All VC
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter === "Token VC"
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              setFilter("Token VC");
              setCurrPage(1);
            }}
          >
            Tokens
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-2 mb-1">
          <button
            className={
              filter === "Pending"
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              setFilter("Pending");
              setCurrPage(1);
            }}
          >
            Pending Tokens
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter === "Cancelled"
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              setFilter("Cancelled");
              setCurrPage(1);
            }}
          >
            Cancelled Tokens
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter === "Declined"
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              setFilter("Declined");
              setCurrPage(1);
            }}
          >
            Declined Tokens
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter === "In-Review"
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              setFilter("In-Review");
              setCurrPage(1);
            }}
          >
            In-Review Tokens
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter === "Accepted"
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              setFilter("Accepted");
              setCurrPage(1);
            }}
          >
            Accepted Tokens
          </button>
        </div>
      </div>
      <div className="my-3 border-top"></div>
      <div className="row">
        <div className="col-2 mb-1">
          <button
            className={
              filter.includes("Slash Token VC")
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              filter === "Active" || filter === "Inactive"
                ? setFilter(`${filter} Slash Token VC`)
                : filter.includes("Token Transfer VC")
                ? setFilter(
                    filter.replace("Token Transfer VC", "Slash Token VC")
                  )
                : filter.includes("Mint Token VC")
                ? setFilter(filter.replace("Mint Token VC", "Slash Token VC"))
                : setFilter("Slash Token VC");
              setCurrPage(1);
            }}
          >
            Slash Token
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter.includes("Mint Token VC")
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              filter === "Active" || filter === "Inactive"
                ? setFilter(`${filter} Mint Token VC`)
                : filter.includes("Token Transfer VC")
                ? setFilter(
                    filter.replace("Token Transfer VC", "Mint Token VC")
                  )
                : filter.includes("Slash Token VC")
                ? setFilter(filter.replace("Slash Token VC", "Mint Token VC"))
                : setFilter("Mint Token VC");
              setCurrPage(1);
            }}
          >
            Mint Token
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter.includes("Token Transfer VC")
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              filter === "Active" || filter === "Inactive"
                ? setFilter(`${filter} Token Transfer VC`)
                : filter.includes("Mint Token VC")
                ? setFilter(
                    filter.replace("Mint Token VC", "Token Transfer VC")
                  )
                : filter.includes("Slash Token VC")
                ? setFilter(
                    filter.replace("Slash Token VC", "Token Transfer VC")
                  )
                : setFilter("Token Transfer VC");
              setCurrPage(1);
            }}
          >
            Token Transfer
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-2 mb-1">
          <button
            className={
              filter.includes("Active")
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              filter === "Slash Token VC" ||
              filter === "Mint Token VC" ||
              filter === "Token Transfer VC"
                ? setFilter(`Active ${filter}`)
                : filter.includes("Inactive")
                ? setFilter(filter.replace("Inactive", "Active"))
                : setFilter("Active");
              setCurrPage(1);
            }}
          >
            Active VC
          </button>
        </div>
        <div className="col-2 mb-1">
          <button
            className={
              filter.includes("Inactive")
                ? "btn btn-outline-dark btn-rounded btn-block active"
                : "btn btn-outline-dark btn-rounded btn-block"
            }
            type="button"
            onClick={() => {
              filter === "Slash Token VC" ||
              filter === "Mint Token VC" ||
              filter === "Token Transfer VC"
                ? setFilter(`Inactive ${filter}`)
                : filter.includes("Active")
                ? setFilter(filter.replace("Active", "Inactive"))
                : setFilter("Inactive");
              setCurrPage(1);
            }}
          >
            Inactive VC
          </button>
        </div>
      </div>
      <div className="row my-3">
        <div className="col">
          <label htmlFor="searchTokenName" className="form-label">
            Search Token Name
          </label>
          <input
            type="email"
            className="form-control"
            id="searchTokenName"
            placeholder="Enter Token Name"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      {activepage()}
    </div>
  );
}

export default VCAnalytics;
