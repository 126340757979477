import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { CCard, CCardBody, CCol, CTable, CRow } from "@coreui/react";
import { APIS } from "../config";

// const getBadge = status => {
//   switch (status) {
//     case 'Active': return 'success'
//     case 'Inactive': return 'secondary'
//     case 'Pending': return 'warning'
//     case 'Banned': return 'danger'
//     default: return 'primary'
//   }
// }
//

function Spinner() {
  return (
    <div
      className="row justify-content-center"
      style={{ height: "300px", width: "100%" }}
    >
      <div className="col-1 align-self-center">
        <div className="spinner-border" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
  );
}

const Transaction = (props) => {
  const [tx, setTx] = useState([]);
  const [val, setVal] = useState("");
  const [searchDid, setSearchDid] = useState(localStorage.getItem("did"));
  const [activePage, setActivePage] = useState(1);
  const [group, setGroup] = useState(0);
  const [msg, setMsg] = useState(false);
  const [searchPg, setSearchPg] = useState(null);

  useEffect(() => {
    getData();
    // currentPage !== page && setPage(currentPage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDid, activePage, tx.length]);

  const getData = async () => {
    //  var did = await localStorage.getItem('did')
    console.log("zzzzz", APIS);
    let res1 = await fetch(
      APIS +
        "balances/" +
        searchDid +
        `?page[size]=10&page[number]=${activePage}`,
      {
        headers: new Headers({
          Authorization: localStorage.getItem("login"),
        }),
      }
    );
    res1 = await res1.json();
    // console.log('api call', res1)
    let myArr = [];
    res1.data.map((e) => {
      myArr.push({
        id: e.id,
        from: e.attributes.sender.attributes.address,
        to: e.attributes.destination.attributes.address,
        amt: e.attributes.value / 1000000,
      });
      if (res1.data.length === myArr.length) {
        setTx(myArr);
      }
    });
    if (res1.data.length === 0) {
      setMsg(true);
    } else {
      setMsg(false);
    }
    return res1;
  };

  const formatName = (text) => {
    var threshold = 11; // Start replacing with * after this value
    if (text.length > threshold) {
      text = text.replace(
        new RegExp(".(?=.{0," + (text.length - threshold - 1) + "}$)", "g"),
        "*"
      );
    }
    return text;
  };

  function tableBody() {
    if (!msg) {
      if (tx.length === 0) {
        return <Spinner />;
      } else {
        return (
          <CTable
            items={tx}
            columns={[
              { key: "id",  _props: { scope: 'col' }, label: "Block Id"},
              { key: "from",  _props: { scope: 'col' }, label: "From"},
              { key: "to",  _props: { scope: 'col' }, label: "To"},
              { key: "amt",  _props: { scope: 'col' }, label: "Amount"},
            ]}
            hover
            striped
            // itemsPerPage={5}
            // activePage={page}
            // clickableRows
            // onRowClick={(item) => history.push(`/tnx-details/${item.id}`)}
            // noItemsView={{noItems: 'No Transactions Found' }}
          />
        );
      }
    } else {
      return (
        <>
          <div
            className="row justify-content-center text-center"
            style={{ height: "300px", width: "100%" }}
          >
            <div className="col-4 align-self-center">
              <h4>No Transactions Found</h4>
            </div>
          </div>
        </>
      );
    }
  }

  return (
    <CRow>
      <CCol xl={12}>
        <CCard style={{ marginTop: "168px" }}>
          <div className="m-3">
            <div className="row justify-content-end">
              <div className="col-1">
                <button
                  type="button"
                  className="btn btn-outline-dark"
                  onClick={() => {
                    setSearchDid(localStorage.getItem("did"));
                    setActivePage(1);
                    setGroup(0);
                  }}
                >
                  <span className="bx bx-reset bx-sm"></span>
                </button>
              </div>
              <div className="col-3">
                <input
                  className="form-control"
                  placeholder="Search Did"
                  onChange={(e) => setVal(e.target.value)}
                  type="text"
                />
              </div>
              <div className="col-2">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    setSearchDid(val);
                    setActivePage(1);
                    setGroup(0);
                  }}
                  disabled={val ? false : true}
                >
                  Search
                </button>
              </div>
            </div>
          </div>
          <CCardBody>
            <div
              className="table-wrapper-scroll-y my-custom-scrollbar"
              style={{ "max-height": "600px" }}
            >
              {tableBody()}
              {props.ssid === undefined && (
                <div className="container">
                  <div className="row justify-content-end">
                    <div className="col-5">
                      <div
                        className="btn-toolbar"
                        role="toolbar"
                        aria-label="Pagination"
                      >
                        <div
                          className="btn-group"
                          role="group"
                          aria-label="Pagination"
                        >
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                            onClick={() => {
                              setActivePage(activePage - 1);
                              if (activePage % 5 === 1) {
                                setGroup(group - 1);
                              }
                            }}
                            disabled={activePage === 1}
                          >
                            <span aria-hidden="true">&laquo;</span>
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-dark ${
                              activePage === 5 * group + 1 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivePage(5 * group + 1);
                              setTx([]);
                            }}
                            disabled={
                              tx && tx.length < 10 && 5 * group + 1 > activePage
                                ? true
                                : false
                            }
                          >
                            {5 * group + 1}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-dark ${
                              activePage === 5 * group + 2 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivePage(5 * group + 2);
                              setTx([]);
                            }}
                            disabled={
                              tx && tx.length < 10 && 5 * group + 2 > activePage
                                ? true
                                : false
                            }
                          >
                            {5 * group + 2}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-dark ${
                              activePage === 5 * group + 3 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivePage(5 * group + 3);
                              setTx([]);
                            }}
                            disabled={
                              tx && tx.length < 10 && 5 * group + 3 > activePage
                                ? true
                                : false
                            }
                          >
                            {5 * group + 3}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-dark ${
                              activePage === 5 * group + 4 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivePage(5 * group + 4);
                              setTx([]);
                            }}
                            disabled={
                              tx && tx.length < 10 && 5 * group + 4 > activePage
                                ? true
                                : false
                            }
                          >
                            {5 * group + 4}
                          </button>
                          <button
                            type="button"
                            className={`btn btn-outline-dark ${
                              activePage === 5 * group + 5 ? "active" : ""
                            }`}
                            onClick={() => {
                              setActivePage(5 * group + 5);
                              setTx([]);
                            }}
                            disabled={
                              tx && tx.length < 10 && 5 * group + 5 > activePage
                                ? true
                                : false
                            }
                          >
                            {5 * group + 5}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-dark"
                            onClick={() => {
                              setActivePage(activePage + 1);
                              if (activePage % 5 === 0) {
                                setGroup(group + 1);
                              }
                            }}
                            disabled={tx && tx.length < 10}
                          >
                            <span aria-hidden="true">&raquo;</span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div className="col-3">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Pagination"
                      >
                        <input
                          type="number"
                          min="1"
                          className="mx-2 form-control"
                          placeholder="Enter page no"
                          onChange={(e) => {
                            setSearchPg(e.target.value);
                          }}
                        />
                        <button
                          type="button"
                          className="btn btn-outline-success mx-2"
                          onClick={() => {
                            setGroup(
                              searchPg % 5 === 0
                                ? searchPg / 5 - 1
                                : (searchPg - (searchPg % 5)) / 5
                            );
                            setTx([]);
                            setActivePage(searchPg);
                          }}
                          disabled={searchPg < 1 ? true : false}
                        >
                          Go
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  );
};

export default Transaction;
