import React, { useEffect, useRef, useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { Button, Dropdown, DropdownButton, } from "react-bootstrap";
import "../styles/Newkyc_style.css";
import { PAIRWISE_API, netstat, PAIRWISE_WEBRTC } from "../config";
import { connection as CSK, did, token } from "mui-metablockchain-sdk";
import { Auth } from "aws-amplify";
import { getUserToken } from "../services/loginService";
import { DID_ADMIN_TOOL } from "../config.js";
import Swal from "sweetalert2";

const configuration = {
  iceServers: [{ urls: "stun:stun.1.google.com:19302" }],
};

const Transfer = (props) => {
  let connection = new RTCPeerConnection(configuration);
  const webSocket = useRef(null);
  const messagesRef = useRef({});
  const [name, setName] = useState("");
  const [socketMessages, setSocketMessages] = useState([]);
  const [sender, setSender] = useState(localStorage.getItem("did"));
  const [reciever, setReceiver] = useState("");
  const [amount, setAmount] = useState("");
  const [memo, setMemo] = useState("");
  const [currencyNameList, setCurrencyNameList] = useState();
  const [tokenName, setTokenName] = useState();


  useEffect(() => {
    webSocket.current = new WebSocket(PAIRWISE_WEBRTC);

    webSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("okst:", data);

      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = () => {
      webSocket.current.close();
    };
    return () => webSocket.current.close();
  }, []);

  useEffect(() => {
    let data = socketMessages.pop();
    console.log("okst2:", data);

    if (data) {
      switch (data.type) {
        case "connect":
          checkLoginStatus();
          break;
        case "client-login":
          //   onLogin(data);
          break;

        case "leave":
          console.log(
            "webrtc connection closed because the other peer leave the data channel..."
          );
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [socketMessages]);

  useEffect(() => {
    getTokenNames();
  }, []);

  const checkLoginStatus = () => {
    send({
      type: "login",
      name: DID_ADMIN_TOOL,
    });
  };
  const send = (data) => {
    webSocket.current.send(JSON.stringify(data));
  };

  const handleTransfer = async (e) => {
    e.preventDefault();
    console.log("meek", sender);
    setName(sender);
    let provider = await CSK.buildConnection(netstat);
    let convertName = await did.resolveDIDToAccount({identifier: sender}, provider);
    // console.log(convertName)
    if(tokenName) {
      var data = {
        sender: sender,
        source: "ssid",
        amount: amount,
        reciever: reciever,
        tokenName: tokenName.currencyCode === "MUI" ? "Metamui" : tokenName.currencyCode,
        tName: tokenName.name,
      };
      send({
        type: "transfer-request",
        data: data,
        token: props.idToken,
      });
    } else {
      Swal.fire("Select a token");
    }
    // if (convertName !== null) {
    //   await fetch(`${PAIRWISE_API}/vc-check`, {
    //     method: "post",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //       did: sender,
    //       source: "ssid",
    //     }),
    //   })
    //     .then((res) => res.json())
    //     .then((res) => {
    //       if (res === true) {
    //         console.log("Login user status:", res);
    //         send({
    //           type: "transfer-request",
    //           name: sender,
    //           source: "ssid",
    //           package: {
    //             sender,
    //             reciever,
    //             amount,
    //             memo,
    //           },
    //         });
    //       } else {
    //         console.log("Login user status:", res);
    //         return;
    //       }
    //     })
    //     .catch((err) => console.log("API ERROR"));
    // }
    return;
  };

  console.log("incoming props:", props);
  console.log(reciever);
  const getTokenNames = async () => {
    try {
      let api = await CSK.buildConnection(netstat);
      const tokenList = await token.getTokenList(api);
      const currencyName = tokenList.map((x) => x);
      currencyName.push({
        name: "Metamui",
        decimal: "6",
        currencyCode: "MUI"
      });
      setCurrencyNameList(currencyName);
    } catch (error) {
      console.log(error);
    }
  };
  const onChangeAmount = (value) => {
    var t = value;
    var name =
      t.indexOf(".") >= 0
        ? t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 7)
        : t;
    setAmount(name);
  };
  if (props.idToken === props.match.params.id) {
    return (
      <div style={{ marginTop: "137px" }}>
        <Container className="kyc_container_div">
          <Row>
            <Col>
              <div className="kycform_div">
                <Form id="contact-form">
                  <Form.Group className="mb-3">
                    <Row>
                      <Form.Label className="is_name_div">
                        Sender DID
                      </Form.Label>
                      <Col md={6}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="name"
                          value={sender}
                          disabled
                        />
                      </Col>
                      {/* <Col md={10}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="name"
                          id="first_name"
                          name="first_name"
                          // onChange={(e) => setSender(`did:ssid:${e.target.value}`)}
                          
                          placeholder="Enter Your Own DID"
                          required
                        />
                      </Col> */}
                    </Row>
                  </Form.Group>
                  {/* <Form.Group className="mb-3">
                    <Row>
                      <Form.Label className="is_name_div">
                        Receiver DID
                      </Form.Label>

                      <Col md={2}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="name"
                          value="did:ssid:"
                          disabled
                        />
                      </Col>
                      <Col md={8}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="text"
                          id="resAdd"
                          name="resAdd"
                          onChange={(e) => setReceiver(e.target.value)}
                          placeholder="Enter Destination DID (did:ssid:xyz)"
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group> */}
                  <Form.Group className="mb-3">
                    <Row>
                      <Form.Label className="is_name_div">
                        Receiver DID
                      </Form.Label>

                      <Col md={10}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="text"
                          id="resAdd"
                          name="resAdd"
                          onChange={(e) => setReceiver(e.target.value)}
                          placeholder="Enter Destination DID (did:ssid:xyz)"
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Row>
                      <Form.Label className="is_name_div">Memo</Form.Label>

                      <Col md={10}>
                        {" "}
                        <Form.Control
                          className="input_div"
                          type="text"
                          id="resAdd"
                          name="resAdd"
                          onChange={(e) => setMemo(e.target.value)}
                          placeholder="Enter Memo"
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  {/* {netstat === "dev" && ( */}
                    <Form.Group className="mb-3">
                      <Row>
                        <Form.Label className="is_name_div">Token</Form.Label>

                        <Col md={10}>
                          {" "}
                          <DropdownButton
                            id="dropdown-basic-button"
                            title={
                              tokenName === undefined
                                ? "Select Token"
                                : tokenName.name +
                                  " " +
                                  `(${tokenName.currencyCode})`
                            }
                          >
                            <div style = {{height: "200px", overflowY: "scroll"}}>
                            {currencyNameList !== undefined
                              ? currencyNameList.map((x, ind) => (
                                  <Dropdown.Item
                                    onClick={() => setTokenName(x)}
                                    key = {ind}
                                  >
                                    {x.name + " " + `(${x.currencyCode})`}
                                  </Dropdown.Item>
                                ))
                              : "Loading...."}
                            </div>
                          </DropdownButton>
                        </Col>
                      </Row>
                    </Form.Group>
                  {/* )} */}
                  <Form.Group>
                    <Row>
                      <Form.Label className="is_name_div">Amount</Form.Label>
                      <Col md={6}>
                        <Form.Control
                          className="input_div"
                          type="text"
                          id="passport"
                          name="passport"
                          value={amount}
                          onChange={
                            (e) => onChangeAmount(e.target.value)

                            // setAmount(
                            //   parseFloat(e.target.value).toFixed(
                            //     tokenName === undefined ? 6 : tokenName.decimal
                            //   )
                            // )
                          }
                          placeholder="Enter Amount "
                          required
                        />
                      </Col>
                    </Row>
                  </Form.Group>
                  <Button
                    type="button"
                    className="btn-div"
                    variant="primary"
                    type="submit"
                    onClick={(e) => handleTransfer(e)}
                  >
                    Transfer
                  </Button>
                  <Button
                    type="button"
                    className="btn_div"
                    variant="secondary"
                    type="reset"
                  >
                    Reset
                  </Button>
                  <p style={{ marginTop: "20px" }}>
                    <strong>
                      Note : On Clicking 'Transfer' button, you are actually
                      establishing pairwise connection with your ssid app and
                      requesting fund transfer for behalf of admin panel from
                      ssid app
                    </strong>
                  </p>
                </Form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  } else {
    return <div>No accessable</div>;
  }
};

export default Transfer;
