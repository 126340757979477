import ZoomIn from '../Assets/icons/zoom-in.png'
import ZoomOut from '../Assets/icons/zoom-out.png'
import React from 'react'
import { Modal } from 'react-bootstrap'
import '../styles/zoom_modal.css';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'

function ImageModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Document Image
        </Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ textAlign: 'center' }}>
        <TransformWrapper
          initialScale={1}
        >
          {({ zoomIn, zoomOut, ...rest }) => (
            <>
              <div className="row justify-content-end">
                <div className="col-1">
                  <img src={ZoomIn} alt="zoom-in" onClick={() => zoomIn()} />
                </div>
                <div className='col-1'>
                  <img src={ZoomOut} alt="zoom-out" onClick={() => zoomOut()} />
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-9">
                  <TransformComponent>
                    <img style={img_doc} src={props.img} alt="document" />
                  </TransformComponent>
                </div>
              </div>
            </>
          )}
        </TransformWrapper>
      </Modal.Body>

    </Modal>
  );
}
export default ImageModal;

const img_doc = {
  width: "auto",
  height: "30rem",
}