import React, { useEffect, useRef, useState } from "react";
// import styled from "styled-components";
// import { Button } from "react-bootstrap";
import "../styles/Newkyc_style.css";
// import { Form,Field } from "react-final-form";
import { PAIRWISE_API, netstat, PAIRWISE_WEBRTC } from "../config";
import { connection as CSK, did, token } from "mui-metablockchain-sdk";
import { DID_ADMIN_TOOL } from "../config.js";

const ManageAdmins = (props) => {
  const webSocket = useRef(null);
  const [socketMessages, setSocketMessages] = useState([]);
  const [sender] = useState(localStorage.getItem("did"));
  const [listItems, setListItems] = useState([]);
  const [userInput, setUserInput] = useState(null);
  const [isValid, setIsValid] = useState("");
  // const [state, setState] = useState(isValid,isInvalid,isChecking,error);
  const [t, setT] = useState(true);

  useEffect(() => {
    webSocket.current = new WebSocket(PAIRWISE_WEBRTC);

    webSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("okst:", data);

      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = () => {
      webSocket.current.close();
    };
    return () => webSocket.current.close();
  }, []);

  useEffect(() => {
    let data = socketMessages.pop();
    console.log("okst2:", data);

    if (data) {
      switch (data.type) {
        case "connect":
          checkLoginStatus();
          break;
        case "client-login":
          // onLogin(data);
          break;
        case "leave":
          console.log(
            "webrtc connection closed because the other peer leave the data channel..."
          );
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [socketMessages]);
  const checkLoginStatus = () => {
    send({
      type: "login",
      name: DID_ADMIN_TOOL,
    });
  };
  const send = (data) => {
    webSocket.current.send(JSON.stringify(data));
  };

  const handleTransfer = async (e) => {
    e.preventDefault();
    // console.log(convertName)
    var data = {
      sender: sender,
      source: "yidindji",
      dids: listItems,
    };
    console.log(data);
    send({
      type: "add-admins",
      data: data,
      token: props.idToken,
    });
  }
  async function addDid(){
    //valid did check
    let cusInput = listItems;
    if (userInput) {
      let provider = await CSK.buildConnection(netstat);
      let AdminDID = await did.resolveDIDToAccount({identifier: userInput}, provider);
      if (!AdminDID) {
        setIsValid("false");
        console.log("not a valid did");
        setListItems(cusInput);
      } else {
        setIsValid("true");
        cusInput.push(userInput);
        setListItems(cusInput);
      }
      // setRi(!rI);
      setUserInput(" ");
    }
    return;
  };
  function removePairwiseAdminDid(index) {
    const newArr = listItems;
    newArr.splice(index, 1);
    setListItems(newArr);
    setT(!t);
  }
  return (
    <>
      <div className="card bg-light mx-4"  style={{ marginTop: "150px" }}>
        <div className="card-body">
          <div className="row justify-content-center mb-3 text-center">
            <div className="col-6">
              <h4>Manage Admin</h4>
            </div>
          </div>
          <div>
            <div
              className="row justify-content-center"
              style={{ marginTop: "30px" }}
            >
              <div className="col-9">
                <input
                  type="text"
                  className={
                    userInput === null
                      ? "form-control"
                      : isValid === "true"
                      ? "form-control is-valid"
                      : isValid === "false"
                      ? "form-control is-invalid"
                      : "form-control"
                  }
                  placeholder="Enter a valid Did"
                  onChange={(e) => setUserInput(e.target.value)}
                />
              </div>
              <div className="col-3">
                <span
                  className="btn btn-primary btn-block"
                  onClick={(e) => addDid(e)}
                >
                  Add
                </span>
              </div>
            </div>
            {isValid === "nil" ? (
              <div className="text-loader">
                <small>Checking!</small>
              </div>
            ) : (
              <>
                {isValid === "false" && (
                  <div className="text-danger">
                    <small>Invalid did!</small>
                  </div>
                )}
                {isValid === "true" && (
                  <div className="text-success">
                    <small>Looks Good!</small>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="row text-center justify-content-center">
            {listItems.length>0 && listItems.map((item, index) => (
              <div className="mt-2 border-bottom" key={index + 1}>
                <div className="row justify-content-between">
                  <div className="col-8 mb-2">{item}</div>
                  {/* {console.log(item)} */}
                  <div className="col-3 mb-2">
                    <span
                      className="btn btn-outline-danger btn-block"
                      onClick={() => removePairwiseAdminDid(index) && listItems}
                    >
                      Remove
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="row justify-content-center">
            <div className="col-3 mt-3 ">
              <button
                className="btn btn-outline-primary btn-block "
                type="submit"
                // disabled={submitting}
                onClick={(e)=>handleTransfer(e)}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageAdmins;
