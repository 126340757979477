import React, { useState } from "react";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isDev } from "./config";
import Login from "./screens/Login";
import KycDashboard from "./screens/KycDashboard";
import SideBar from "./components/SideBar";
import auth from "./services/loginService";
import RestoreVCRequests from "./screens/RestoreVCRequests";
import SubAdmin from "./screens/SubAdmin";
import EditUsers from "./screens/EditUsers";
import KycDetails from "./screens/KycDetails";
import Newkyc from "./components/Newkyc";
import Transfer from "./screens/Transfer";
import UserSignup from "./components/UserSignup";
import UserSignin from "./components/UserSignin";
// import Homedash from "./components/Homedash";
import Transaction from "./screens/Transaction";
import OtpScreen from "./screens/OtpScreen";
import CreateToken from "./screens/CreateToken";
import ListToken from "./screens/ListToken";
import useIsMobile from "./components/useIsMobile";
import HeaderMobile from "./components/HeaderMobile";
import BTC from "./screens/BTC";
import VC from "./screens/VC";
import VCSettings from "./components/VCSettings";
import VCCreate from "./components/VCCreate";
import VCAnalytics from "./components/VCAnalytics";
import TokenVCForm from "./components/BTCForms/TokenVCForm";
import RestoreVCRequestDetails from "./screens/RestoreVCRequestDetails";
import MFALogin from "./screens/MFALogin";
// import NewRegistration from "./components/NewRegistration"
// import NewUserSignup from "./components/NewUserSignup";
import PublicDIDKyc from "./screens/PublicDIDKyc";
import PublicKycDetails from "./screens/PublicKycDetails";
import SudoCouncil from "./screens/SudoCouncil";
import EventLanding from "./screens/EventLanding";
import ManageAdmins from './screens/ManageAdmins';
if (!isDev) {
  console.log = () => {};
  console.info = () => {};
  console.warn = () => {};
}

const ProtectedRoute = ({
  status,
  path,
  component: Component,
  render,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={(props) => {
        console.log(status);
        if (!status)
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        return Component ? (
          <Component {...props} idToken={status} />
        ) : (
          render(props)
        );
      }}
    />
  );
};

function App() {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const pairwiseLogin = localStorage.getItem("login");
  const isMobile = useIsMobile();

  // FIX-ME: this will call the function on each rerender
  // Need urgent optimization
  auth
    .getCurrentUser()
    .then((data) => {
      setIsAuthenticated(data?.idToken?.jwtToken ?? false);
      setLoading(false);
      // console.log("auth",data)
      return data;
    })
    .catch((err) => {
      console.log(err);
      if (pairwiseLogin !== null) {
        setIsAuthenticated(pairwiseLogin);
        setLoading(false);
      } else {
        setIsAuthenticated(false);
        setLoading(false);
      }
    });

  if (loading) {
    console.log("Loading");
    return <p style={{ color: "red" }}>Loading</p>;
  }
  // debugger
  return (
    <BrowserRouter>
      <main className="container">
        <ToastContainer />
        <Switch>
          <Route path="/login" component={Login} />
          <Route path="/mfa" exact component={MFALogin} />
          <body id="body-pd">
            {!isMobile ? <SideBar /> : <HeaderMobile />}
            {/* <SideBar /> */}

            <Switch>
              <ProtectedRoute
                path="/"
                exact
                component={KycDashboard}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/subusers"
                exact
                component={SubAdmin}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/edit_users/:id"
                exact
                component={EditUsers}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/restore_vc_request"
                exact
                component={RestoreVCRequests}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/restore_vc_details"
                exact
                component={RestoreVCRequestDetails}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/transfer/:id"
                exact
                component={Transfer}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/transaction/:id"
                exact
                component={Transaction}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/create-token/:id"
                exact
                component={CreateToken}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/list-token/:id"
                exact
                component={ListToken}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/new_kyc"
                exact
                component={Newkyc}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/btc"
                exact
                component={BTC}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/vc"
                exact
                component={VC}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/vc/create-new-vc"
                exact
                component={VCCreate}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/vc/all-vc"
                exact
                component={VCAnalytics}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/vc/my-vc"
                exact
                component={VCSettings}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/vc/create-new-vc/token-admin-vc"
                exact
                component={TokenVCForm}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/kyc_user_details"
                exact
                component={KycDetails}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/kyc_public_details"
                exact
                component={PublicKycDetails}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/public_did_details"
                exact
                component={PublicDIDKyc}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/sudo-actions"
                exact
                component={SudoCouncil}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/events"
                exact
                component={EventLanding}
                status={isAuthenticated}
              />
              <ProtectedRoute
                path="/manage-admins"
                exact
                component={ManageAdmins}
                status={isAuthenticated}
              />
              <Route path="/usersignup" exact component={UserSignup} />
              <Route path="/usersignin" exact component={UserSignin} />
              <Route path="/otpscreen" exact component={OtpScreen} />
             
            </Switch>
          </body>
          <Redirect to="/404" />
        </Switch>
      </main>
    </BrowserRouter>
  );
}

export default App;
