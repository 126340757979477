import axios from 'axios';
import {KYC_BUCKET_NAME, BASE_URL} from '../config'

const PRESIGN_URL_S3 = '/generatePresignedS3AdminUrl';
/**
 * Upload file to S3 with previously received pre-signed POST data.
 * @param presignedPostData
 * @param file
 * @returns {Promise<any>}
 */
export const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    Object.keys(presignedPostData.fields).forEach((key) => {
      formData.append(key, presignedPostData.fields[key]);
    });
    // Actual file has to be appended last.
    formData.append('Content-Type', file.type);
    formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    axios
      .post(presignedPostData.url, formData, config)
      .then((res) => {
        const {
          url,
          fields: {key},
        } = presignedPostData;
        const data = `${url}${key}`;
        // Resolve the file url
        resolve(data);
      })
      .catch((err) => reject(err));
  });
};
/**
 * Fetch the presigned url to which upload should happen.
 * @param {String} idToken Secure Token
 * @param {String} fileName Name of file to be uploaded
 * @returns {Promise} Presigned url api promise.
 */
export const getS3PresignedData = (
  idToken,
  fileName,
  bucketParam,
  support,
  mimetype,
  email,
  phone_number,
) => {
  return new Promise((resolve, reject) => {
    // Get the presigned object
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: idToken,
      },
      params: {
        bucket: KYC_BUCKET_NAME,
        file_name: fileName,
        content_type: mimetype,
        email: email,
        phone_number: phone_number,
      },
    };
    if (support) {
      config.params.type = 'support';
    }
    axios
      .get(`${BASE_URL}${PRESIGN_URL_S3}`, config)
      .then((res) => resolve({status: res.status, data: res.data.message}))
      .catch((err) => reject(err));
  });
};
