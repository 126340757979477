import { useRef, useState, useEffect } from "react";
export const useWebSocketHook = (url, methods) => {
  const webSocketRef = useRef(null);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    console.log(url);
    const ws = new WebSocket(url);
    console.log("Web socket initialized v2");

    ws.onmessage = (event) => {
      const message = JSON.parse(event.data);
      console.log("Received message:", message);
      setMessages((prevMessages) => [...prevMessages, message]);
    };
    ws.onerror = (err) => {
      console.log("Websocket error");
      console.log(err);
    };

    ws.onclose = () => {
      console.log("WebSocket closed");
      ws.close();
    };

    webSocketRef.current = ws;

    return () => {
      console.log("Cleaning up WebSocket");
      ws.close();
    };
  }, [url]);

  useEffect(() => {
    if (messages.length > 0) {
      const message = messages.pop();
      console.log("Handling message:", message);
      if (methods[message.event]) {
        methods[message.event]({ message, send: sendData });
      }
    }
  }, [messages]);

  const sendData = (data) => {
    if (webSocketRef.current.readyState === WebSocket.OPEN) {
      webSocketRef.current.send(JSON.stringify(data));
    } else {
      console.error("WebSocket is not open");
    }
  };

  return { webSocketSend: sendData };
};
