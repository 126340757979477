import React, { useState, useEffect } from "react";
import { Card, Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { GetCall } from "../services/KycService";
import "../styles/Sidemenu_style.css";
import MobileClass from "./MobileClass";
import search_icon from "../Assets/icons/Search.png";

function TableGrid (props) {
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     selected_kyc_status: "S",
  //     selected_Button: "S",
  //     R_Button: false,
  //     S_Button: true,
  //     D_Button: false,
  //     A_Button: false,
  //     query: "",
  //     searchString: [],
  //     users: [],
  //     filteredUsers: [],
  //     isLoading: true,
  //   };
  // }

  const [selected_kyc_status, setSelected_kyc_status] = useState("S");
  const [selected_Button, setSelected_Button] = useState("S");
  const [R_Button, setR_Button] = useState(false);
  const [S_Button, setS_Button] = useState(true);
  const [D_Button, setD_Button] = useState(false);
  const [A_Button, setA_Button] = useState(false);
  const [query, setQuery] = useState("");
  const [searchString, setSearchString] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // async componentDidMount() {
  //   const res = await GetCall("getKycList");
    
  //   if (res.message) {
  //     // this.setState({ users: res.message.filter(item => item.hasOwnProperty('kyc_status')), isLoading: false });
  //     const filterUsers = res.message.filter(item => item.hasOwnProperty('kyc_status') && 
  //                         (item.hasOwnProperty ("kycType") ? (item.kycType !== "PublicDid" ? true : false ) : true))
  //     this.setState({ users: filterUsers, isLoading: false });
  //     const { users } = this.state;
      
  //     const submittedUsers = users.filter((e) => e.kyc_status.includes("S"));
  //     this.setState({ filteredUsers: submittedUsers });
  //   } else {
  //     throw new Error("API is not returning the data");
  //   }
  // }

  useEffect(() => {
    async function fetchData() {
      const res = await GetCall("getKycList");
      if (res.message) {
        // this.setState({ users: res.message.filter(item => item.hasOwnProperty('kyc_status')), isLoading: false });
        const filterUsers = res.message.filter(item => item.hasOwnProperty('kyc_status') && (item.hasOwnProperty("kycType") ? (item.kycType !== "PublicDid" ? true : false) : true))

        setUsers(filterUsers);
        // console.log(filterUsers);
        setIsLoading(false);

        const submittedUsers = filterUsers.filter((e) => e.kyc_status === "S");
        setFilteredUsers(submittedUsers);

        // console.log(new Date(filterUsers[0].timestamp))
      } else {
        throw new Error("API is not returning the data");
      }

    }
    fetchData();
  },[]);

  function changeButtonstatus(data) {
    if (data === "A") {
      // this.setState({
      //   A_Button: true,
      //   S_Button: false,
      //   D_Button: false,
      //   R_Button: false,
      //   selected_Button: "A",
      // });
      // const { users } = this.state;
      setSelected_Button("A");
      setA_Button(true);
      setS_Button(false);
      setD_Button(false);
      setR_Button(false);
      const acceptedUsers = users.filter((e) => e.kyc_status === "A");
      console.log(acceptedUsers);
      // this.setState({ filteredUsers: acceptedUsers });
      setFilteredUsers(acceptedUsers);
    } else if (data === "D") {
      // this.setState({
      //   A_Button: false,
      //   S_Button: false,
      //   D_Button: true,
      //   R_Button: false,
      //   selected_Button: "D",
      // });
      // const { users } = this.state;
      setSelected_Button("D");
      setA_Button(false);
      setS_Button(false);
      setD_Button(true);
      setR_Button(false);
      const declinedUsers = users.filter((e) => e.kyc_status === "D");
      console.log(declinedUsers);
      // this.setState({ filteredUsers: declinedUsers });
      setFilteredUsers(declinedUsers);
    } else if (data === "S") {
      // this.setState({
      //   A_Button: false,
      //   S_Button: true,
      //   D_Button: false,
      //   R_Button: false,
      //   selected_Button: "S",
      // });
      // const { users } = this.state;
      setA_Button(false);
      setS_Button(true);
      setD_Button(false);
      setR_Button(false);
      setSelected_Button("S");
      const submittedUsers = users.filter((e) => e.kyc_status === "S");
      console.log(submittedUsers);
      // this.setState({ filteredUsers: submittedUsers });
      setFilteredUsers(submittedUsers);
    } else {
      // this.setState({
      //   A_Button: false,
      //   S_Button: false,
      //   D_Button: false,
      //   R_Button: true,
      //   selected_Button: "R",
      // });
      // const { users } = this.state;
      setSelected_Button("R");
      setA_Button(false);
      setS_Button(false);
      setD_Button(false);
      setR_Button(true);
      const reviewUsers = users.filter((e) => e.kyc_status === "R");
      console.log(reviewUsers);
      // this.setState({ filteredUsers: reviewUsers });
      setFilteredUsers(reviewUsers);
    }
  }
  const handleInputChange = (event) => {
    // this.setState({
    //     query: event.target.value
    // })
    setQuery(event.target.value);
}
const renderTable = () => {
    
  let searchString = query;

 
      // console.log(responseData[i].name);
      // responseData = responseData.filter(searchString);

return filteredUsers
  .sort((a, b) => {return new Date(b.timestamp) > new Date(a.timestamp) ? 1 : -1})
  .map((user, index) => {
    if( (user.hasOwnProperty('first_name') && 
      user.first_name.toLowerCase().includes(searchString.toLowerCase())) || 
      String(user.email).includes(searchString))
    {
    const dateString = user.timestamp;
    const indexNumber = index + 1;
    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const decodeString = (string) =>{
      if(string) {
        if(string.indexOf('&amp;') !== -1) {
          let newS =  string.replace(/&amp;/g, '&');
          if(newS.indexOf('&amp;') !== -1) {
            decodeString(newS)
          } else {
            return newS;
          }
        } else {
          return string;
        }
      }
    }
    

    const date = formatDate(dateString);
    return (
      <>
        <tbody style={{ textAlign: "center" }}>
          <tr>
            <td>{indexNumber}</td>
            <td>
              {user.first_name ? decodeString(user.first_name) : decodeString(user.first_name)}{" "}
              {user.last_name ? decodeString(user.last_name) : decodeString(user.last_name)}
            </td>
            <td> {user.email ? user.email : user.phoneNumber || user.phone_number} </td>
            <td>{date}</td>
            <td>
              <Button
                className="view_btn"
                style={btn_details}
                onClick={() => userClick(user.uid)}
              >
                View
              </Button>
            </td>
          </tr>
        </tbody>

      </>
    );
  }
  else if(searchString === "") {
    const dateString = user.timestamp;
    const indexNumber = index + 1;
    const formatDate = (dateString) => {
      const options = { year: "numeric", month: "numeric", day: "numeric" };
      return new Date(dateString).toLocaleDateString(undefined, options);
    };
    const decodeString = (string) =>{
      if(string) {
        if(string.indexOf('&amp;') !== -1) {
        let newS =  string.replace(/&amp;/g, '&');
        if(newS.indexOf('&amp;') !== -1) {
          decodeString(newS)
        } else {
          return newS;
        }
      } else {
        return string;
      }
      }
    }

    const date = formatDate(dateString);

    return (
      <>
        <tbody style={{ textAlign: "center" }}>
          <tr>
            <td>{indexNumber}</td>
            <td>
              {user.first_name ? decodeString(user.first_name) : decodeString(user.first_name)}{" "}
              {user.last_name ? decodeString(user.last_name) : decodeString(user.last_name)}
            </td>
            <td> {user.email ? user.email : user.phoneNumber || user.phone_number} </td>
            <td>{date}</td>
            <td>
              <Button
                className="view_btn"
                style={btn_details}
                onClick={() => userClick(user.uid)}
              >
                View
              </Button>
            </td>
          </tr>
        </tbody>
        </>
    )
  }
  });


};


  const userClick = (data) => {
    props.history.push("/kyc_user_details", { uid: data });
  };

  return (
    <>
      <ButtonST className="btn_st_div">
        <Button
          className="btn_status_div4"
          style={btn_status}
          toggle
          active={S_Button}
          onClick={() => changeButtonstatus("S")}
        >
          SUBMITTED
        </Button>
        <Button
          className="btn_status_div4"
          style={btn_status}
          toggle
          active={D_Button}
          onClick={() => changeButtonstatus("D")}
        >
          DECLINED
        </Button>
        <Button
          className="btn_status_div4"
          style={btn_status}
          toggle
          active={A_Button}
          onClick={() => changeButtonstatus("A")}
        >
          ACCEPTED
        </Button>
      </ButtonST>

      <br />
      <br />
      <div className="nav_input-name">
          
          <input
            className="input_search"
            type="text"
            placeholder="Search Given Name or Email"
            value={query}
            onChange={handleInputChange}
          /> 
          <img src={search_icon} alt="search" className="search_icon_img" /> </div>
      <Wrapper>
        {/* <Card style={cardTable}>
          <Card.Body>
            <Row>
              <Col xs={1}>
                <p className="para_div">#</p>
              </Col>
              <Col>
                <p className="para_div">Name</p>
              </Col>
              <Col>
                <p className="para_div">Email/Mobile Number</p>
              </Col>
              <Col xs={2}>
                <p className="para_div">Date</p>
              </Col>
              <Col xs={2}>
                <p className="para_div">Action</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <br /> */}
        <Table striped bordered variant="light" responsive="sm">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>#</th>
              <th>Full Name</th>
              <th>Email/ Mobile Number</th>
              <th>Date</th>
              <th>Look Up</th>
            </tr>
          </thead>
          {renderTable()}
        </Table>
      </Wrapper>
    </>
  );
}
export default TableGrid;

const cardTable = {
  borderRadius: "15px",
  marginTop: "5px",
  border: "none",
  outline: "none",
};
const Wrapper = styled.div`
  width: 100%;
  background-color: #dce3e8;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 50px;
  @media screen and (min-width: 360px) and (max-width: 767px) {
    font-size: 1rem;
  }
`;
const dropdown_menu = {
  background: "#F2F2F2",
  height: "43px",
  borderRadius: "15px",
  border: "none",
  outline: "none",
};
const btn_status = {
  width: "151px",
  height: "43px",
  // padding: "15px 25px 15px 25px",
  background: "#699EFF",
  borderRadius: "8px",
  color: "white",
};

const btn_status_active = {
  width: "151px",
  height: "43px",
  background: "#699EFF",
  borderRadius: "8px",
  color: "white",
};

const btn_details = {
  background: "#0D9E35",
  borderRadius: "8px",
  width: "100px",
  color: "#fff",
  fontSize: "15px",
  fontWeight: "600",
  border: "none",
  outline: "none",
  padding: "5px",
};
const ButtonST = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (min-width: 360px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
  }
`;