import React from 'react';

function BTCCertificate(props) {

	function getSponsors() {
		let dids = props.data.sponsorSignatories;
		let elements = [];
		for(let i=0; i<dids.length; ++i) {
			elements.push (
				<div key = {i} className = "mb-2"><h5>{dids[i]}</h5></div>
			);
		}
		return elements;
	}

	return (
		<div className = "card mt-3">
		<img src = "/android-chrome-512x512.png" className = "card-img" style = {{opacity: 0.2, height: "360px"}} alt = ""/>
			<div className = "card-body card-img-overlay">
				<div className = "row text-center mt-3">
					<div className = "col">
						<h3><b>Meta MUI Certificates</b></h3>
					</div>
				</div>
				<div className = "row text-center mt-3">
					<div className = "col">
						<h1 className = "fw-bold fst-italic">
							Certificate of {props.data.instrumentType}
						</h1>
					</div>
				</div>
				<div className = "row text-start mt-3">
					<div className = "col">
						<h5><b>Serial Number:</b>&emsp;{props.data.serialCode}</h5>
					</div>
				</div>
				<div className = "row text-center mt-3">
					<div className = "col">
						<h5 className = "mb-2"><b>{props.data.notes}</b></h5>
						<h5 className = "mb-2"><b>{props.data.amount}&emsp;MUI Tokens</b></h5>
						<h5 className = "mb-2"><b>For Payment To:</b>&emsp;{props.data.exchangeTo}</h5>
					</div>
				</div>
				<div className = "row justify-content-between mt-3">
					<div className = "col-4 text-start">
						<h5><b>Date:</b>&emsp;{props.data.createdOn}</h5>
					</div>
					<div className = "col-8 text-end">
						<div className = "row justify-content-end mb-3">
							<div className = "col-4">
								<h5><b>Primary Signatory:</b></h5>
							</div>
							<div className = "col-4">
								<h5>{props.data.primarySignatory}</h5>
							</div>
						</div>
						<div className = "row justify-content-end">
							<div className = "col-4">
								<h5><b>Sponsor Signatories:</b></h5>
							</div>
							<div className = "col-4">
								<div className = "d-flex flex-column mb-2">
									{getSponsors()}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BTCCertificate;