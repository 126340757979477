import React, {useState, useEffect, useRef} from 'react';
import {netstat, PAIRWISE_API, PAIRWISE_WEBRTC} from '../config';
import {connection, collective, utils, did} from 'mui-metablockchain-sdk'
import {GetToken} from '../services/vcService'
import Swal from 'sweetalert2';

function SudoCouncil() {
  const [members, setMembers] = useState(null);
  const [primeMembers, setPrimeMembers] = useState(null);
  const [primeMsgStatus, setPrimeMsgStatus] = useState("Fetch")
  const [councilMsgStatus, setCouncilMsgStatus] = useState("Fetch")
  const [newMems, setNewMems] = useState([])
  const [newPrime, setNewPrime] = useState()
  const [addCm, setAddCm] = useState(false)
  const [addPm, setAddPm] = useState(false) 
  const [inputCm, setInputCm] = useState()
  const [inputPm, setInputPm] = useState()
  const [stateTrigger, setStateTrigger] = useState(false)
  const webSocket = useRef(null);
  const [socketMessages, setSocketMessages] = useState([]);

  const send = (data) => {
    webSocket.current.send(JSON.stringify(data));
  };


  useEffect(() => {
    webSocket.current = new WebSocket(PAIRWISE_WEBRTC);

    webSocket.current.onmessage = (message) => {
      const data = JSON.parse(message.data);
      console.log("okst:", data);

      setSocketMessages((prev) => [...prev, data]);
    };
    webSocket.current.onclose = () => {
      webSocket.current.close();
    };
    return () => webSocket.current.close();
  }, []);

  useEffect(() => {
    let data = socketMessages.pop();
    console.log("okst2:", data);

    if (data) {
      switch (data.type) {
        case "connect":
          checkLoginStatus();
          break;
        case "leave":
          console.log(
            "webrtc connection closed because the other peer leave the data channel..."
          );
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [socketMessages]);
  const checkLoginStatus = () => {
    send({
      type: "login",
      name: "did:ssid:v3ssid-admin",
    });
  };


  useEffect(() => {
    getMembers();
  }, [])

  const submitNewCouncil = async() => {
    let obj = {
      sender: localStorage.getItem('did'),
      source: "ssid",
      council_data : {
        newMembers : newMems,
        prime : newPrime ? newPrime : "",
        oldCount : members.length,
        isCouncil : true
      },
      type : "set-council"
    }
    const token = await GetToken()
    send({
      type: "transfer-request",
      data: obj,
      token: token,
    });
  }
  
  const getMembers = async () => {
    let cm, pm;
    let resolvedCouncil = [];
    // let resolvedPrime = [];
    let provider = await connection.buildConnection(netstat);
    try {
      cm = await collective.getMembers(provider);
      if(cm) {
        for (let i = 0; i < cm.length; ++i) {
          let did = utils.hexToString(cm[i]);
          resolvedCouncil.push(did);
        }
        if(resolvedCouncil.length > 0) {
          setCouncilMsgStatus("Success")
          setMembers(resolvedCouncil);
          setNewMems(resolvedCouncil);
        }
      } else {
        setCouncilMsgStatus("Nothing")
      }
    } catch (err) {
      setCouncilMsgStatus("Error")
    }
    try {
      pm = await collective.getPrime(provider);
      if(pm) {
        let did = utils.hexToString(pm);
        setPrimeMsgStatus("Success")
        setPrimeMembers(did);
        setNewPrime(did);
      } else {
        setPrimeMsgStatus("Nothing")
      }
    } catch (err) {
      setPrimeMsgStatus("Error")
    }
  }

  function getCouncilStatus() {
    if(councilMsgStatus === "Fetch") {
      return (
        <tr>
          <td>
          <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
          </div>&emsp;
          <span>Fetching All Council Members</span>
          </td>
        </tr>
      );
    } else if(councilMsgStatus === "Error") {
      return (
        <tr>
          <td>
            Something Went Wrong. Please refresh..
          </td>
        </tr>
      );
    } else if(councilMsgStatus === "Nothing") {
      return (
        <tr>
          <td>
            No Council Members Found
          </td>
        </tr>
      );
    }
  }

  function getPrimeStatus() {
    if(primeMsgStatus === "Fetch") {
      return (
        <tr>
          <td>
          <div className="spinner-border" role="status">
              <span className="visually-hidden">Loading...</span>
          </div>&emsp;
          <span>Fetching All Prime Members</span>
          </td>
        </tr>
      );
    } else if(primeMsgStatus === "Error") {
      return (
        <tr>
          <td>
            Something Went Wrong. Please refresh..
          </td>
        </tr>
      );
    } else if(primeMsgStatus === "Nothing") {
      return (
        <tr>
          <td>
            No Prime Members Found
          </td>
        </tr>
      );
    }
  }

  function removeCouncilMember(mem) {
    let n = newMems;
    let id = newMems.findIndex(item => item === mem);
    n.splice(id, 1);
    setNewMems(n);
    setStateTrigger(!stateTrigger)
  }

  const addCouncilMember = async() => {
    let n = newMems;
    let provider = await connection.buildConnection(netstat);
    let convertName = await did.resolveDIDToAccount({identifier: inputCm}, provider);
    console.log(convertName);
    if(!convertName) {
      Swal.fire("Invalid did added");
    } else {
      n.push(inputCm);
      setNewMems(n);
      setAddCm(false);
      setInputPm(undefined);
    }
  }

  const addPrimeMember = async() => {
    let provider = await connection.buildConnection(netstat);
    let convertName = await did.resolveDIDToAccount({identifier: inputPm}, provider);
    console.log(convertName);
    if(!convertName) {
      Swal.fire("Invalid did added");
    } else {
      setNewPrime(inputPm)
      setAddPm(false);
      setInputPm(undefined)
    }
  }

  return (
    <div className = "card" style = {{marginTop : "90px"}}>
      <h5 className = "pt-5 pb-3 text-center">All Council Members</h5>
      <div className = "row justify-content-center pb-5">
        <table className=" col-8 table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Serial No.</th>
            <th scope="col">DID</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            newMems && newMems.map((e,i) => (
              <tr key = {i}>
                <th className = "w-25" scope="row">{i+1}</th>
                <td>{e}</td>
                <td><button className = "btn btn-outline-danger btn-sm" onClick = {() => removeCouncilMember(e)}>Remove</button></td>
              </tr>
            ))
          }
          {
            getCouncilStatus()
          }
        </tbody>
        </table>
        <div className= "row justify-content-center">
          <div className = "col-2">
            <h6>New Council:&emsp;</h6>
          </div>
          <div className = "col-6">
            {
              newMems && newMems.map((e,i) => <small key = {i} className = "text-secondary">{e}, </small>)
            }
          </div>
        </div>
        <div className = "col-5">
        <button className = {!addCm ? "btn btn-outline-warning btn-sm mt-3" : "d-none"} onClick = {() => setAddCm(true)}>Add New Council Member</button>
        <div className = { addCm ? "m-3" : "d-none"}>
          <label className = "form-label" htmlFor = "councilMember">Enter the did of new council member</label>
          <input className = "form-control" id = "councilMember" placeholder = "Enter a valid did" onChange = {(e) => setInputCm(e.target.value)} />
          <button className = "btn btn-primary mt-2" onClick = {() => addCouncilMember()} disabled = { inputCm ? false : true}>Add</button>
        </div>
        </div>
        </div>
        <h5 className = "pb-3 text-center">Prime Members</h5>
        <div className = "row justify-content-center pb-5">
        <table className=" col-8 table table-bordered table-striped">
        <thead>
          <tr>
            <th scope="col">Serial No.</th>
            <th scope="col">DID</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {
            newPrime && 
              <tr>
                <th className = "w-25" scope="row">1</th>
                <td>{newPrime ? newPrime : "No prime set"}</td>
                <td><button className = "btn btn-outline-danger btn-sm" onClick = {() => {setNewPrime(undefined); setInputPm(undefined)}}>Remove</button></td>
              </tr>
          }
          {
            getPrimeStatus()
          }
        </tbody>
      </table>
      <div className= "row justify-content-center">
          <div className = "col-2">
            <h6>New Prime:&emsp;</h6>
          </div>
          <div className = "col-6">
            <small className = "text-secondary">{newPrime ? newPrime : "No Prime set"}</small>
          </div>
        </div>
        <div className = "col-5">
          <button className = { !addPm ? "btn btn-outline-warning btn-sm mt-3" : "d-none"} onClick = {() => setAddPm(true)}>Add New Prime Member</button>
          <div className = { addPm ? "m-3" : "d-none"}>
            <label className = "form-label" htmlFor = "councilMember">Enter the did of new prime member</label>
            <input className = "form-control" id = "councilMember" placeholder = "Enter a valid did" onChange = {(e) => setInputPm(e.target.value)} />
            <button className = "btn btn-primary mt-2 me-2" onClick = {() => addPrimeMember()} disabled = { newPrime ? true : false }>Add</button>
          </div>
        </div>
      </div>
      <div className = "row justify-content-start ml-3 mb-3">
        <div className = "col-5">
          <button className = "btn btn-outline-primary" onClick = {() => submitNewCouncil()}>Proceed with Pairwise to submit your changes</button>
        </div>
      </div>
    </div>
  );
}

export default SudoCouncil;