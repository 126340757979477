import React, { useState } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import "../styles/Newkyc_style.css";
import Swal from "sweetalert2";
import { Auth } from "aws-amplify";
import axios from "axios";
import { getS3PresignedData, uploadFileToS3 } from "../services/s3Utils";
import { BASE_URL } from "../config"

const Newkyc = (props) => {
  const [first_name, setfirst_name] = useState("");
  const [last_name, setlast_name] = useState("");
  const [dob, setDob] = useState("");
  const [residentalAddr, setResidentalAddr] = useState("");
  const [postalAddr, setPostalAddr] = useState("");
  const [vehicleClass, setVehicleClass] = useState("");
  const [vehicalLicenseNo, setVehicalLicenseNo] = useState("");
  const [passportNumber, setPassportNumber] = useState("");
  const [healthCardNo, setHealthCardNo] = useState("");
  const [housingNo, setHousingNo] = useState("");
  const [citizenshipIDNo, setCitizenshipIDNo] = useState("");
  const [selfieUrl, setSelfieUrl] = useState("");
  const [fileUrl, setFileUrl] = useState("");
  const [selectedFileName, setSelectedFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState("");
  const [selfieName, setSelfieName] = useState("");

  const updatefirst_name = (event) => {
    setfirst_name(event.target.value);
  };
  const updatelast_name = (event) => {
    setlast_name(event.target.value);
  };
  const updateDob = (event) => {
    setDob(event.target.value);
  };
  const updateResidentalAddr = (event) => {
    setResidentalAddr(event.target.value);
  };
  const updatePostalAddr = (event) => {
    setPostalAddr(event.target.value);
  };
  const updateVehicleClass = (event) => {
    setVehicleClass(event.target.value);
  };
  const updateVehicalLicenseNo = (event) => {
    setVehicalLicenseNo(event.target.value);
  };
  const updatePassportNumber = (event) => {
    setPassportNumber(event.target.value);
  };
  const updateHealthCardNo = (event) => {
    setHealthCardNo(event.target.value);
  };

  const updateHousingNo = (event) => {
    setHousingNo(event.target.value);
  };
  const updateCitizenshipIDNo = (event) => {
    setCitizenshipIDNo(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const CitizenFormData = {
      kycType: "Citizen",
      first_name,
      last_name,
      dob,
      residentalAddr,
      postalAddr,
      citizenshipIDNo,
      vehicleClass,
      vehicalLicenseNo,
      passportNumber,
      healthCardNo,
      housingNo,
      document_image_url: fileUrl,
      selfie_image_url: selfieUrl,
    };

    return Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data);
      Object.assign(CitizenFormData, {
        uid: data.idToken.payload.sub,
        email: data.idToken.payload.email,
        phone_number: data.idToken.payload.phone_number,
      });
      axios({
        method: "post",
        url: `${BASE_URL}saveUserKycAdmin`,
        data: CitizenFormData,
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: data.idToken.jwtToken,
        },
      })
        .then((res) => {
          Swal.fire("Success!", "KYC Submitted Successfully", "success");
        })
        .catch((err) => console.log("Error During submit KYC...", err));
    });
  };

  const btnReset = () => {
    setfirst_name("");
    setlast_name("");
    setDob("");
    setVehicleClass("");
    setVehicalLicenseNo("");
    setPostalAddr("");
    setResidentalAddr("");
    setPassportNumber("");
    setHealthCardNo("");
    setHousingNo("");
    setCitizenshipIDNo("");
  };
  const handleLogout = async () => {
    try {
      await Auth.signOut();
      props.history.push("/login");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const selectSelfieImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadSelfieImage(e.target.files[0]);
  };

  const uploadSelfieImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setSelfieName(trimFilename);
    // setSelfieName(trimFileName);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(idToken, name, false, type);
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setSelfieUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", fileUrl);

  const selectImage = (e) => {
    setSelectedFile(e.target.files[0]);
    uploadImage(e.target.files[0]);
  };
  const uploadImage = async (selectedFileObj, docType) => {
    console.log("Selected Image Object are as follows.....", selectedFileObj);
    const { name, type } = selectedFileObj;
    const trimFilename = name.substring(0, 5) + "...";
    // if (docType === "document") {
    //   setSelectedFileName(trimFilename);
    // } else {
    //   setSelfie(trimFilename);
    // }
    setSelectedFileName(trimFilename);
    console.log("Filename is....", name);
    console.log("File type is....", type);
    let idToken;
    await Auth.currentSession().then((data) => {
      console.log("JWTTOKEN", data.idToken.jwtToken);
      idToken = data.idToken.jwtToken;
    });
    const preSignedObj = await getS3PresignedData(idToken, name, false, type);
    uploadFileToS3(preSignedObj.data, selectedFileObj)
      .then((res) => {
        let fileUrl = `${preSignedObj.data.url}${preSignedObj.data?.fields?.key}`;
        setFileUrl(fileUrl);
        // docType === "document" ? setselfieUrl(fileUrl) : setFileUrl(fileUrl);
      })
      .catch((_err) => alert("Upload Failed, Please try again..", _err));
  };
  console.log("url......", fileUrl);
  return (
    <>
      <Container className="kyc_container_div" style={{ marginTop: "50px" }}>
        <Row>
          <Col>
            <Out>
              <button onClick={handleLogout}> Logout </button>
            </Out>
            <Heading> Yidindji Citizenship Form</Heading>
            <div className="kycform_div">
              <Form onSubmit={handleSubmit} id="contact-form">
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Name</Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="first_name"
                        name="first_name"
                        placeholder="Enter Given Name"
                        value={first_name}
                        onChange={updatefirst_name}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="last_name"
                        name="last_name"
                        placeholder="Enter Family Name"
                        value={last_name}
                        onChange={updatelast_name}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Date of Birth
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="date"
                        id="dob"
                        name="dob"
                        placeholder="Date of Birth"
                        value={dob}
                        onChange={updateDob}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Address</Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="resAdd"
                        name="resAdd"
                        placeholder="Enter Residential Address"
                        value={residentalAddr}
                        onChange={updateResidentalAddr}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="posAdd"
                        name="posAdd"
                        placeholder="Enter Postal Address"
                        value={postalAddr}
                        onChange={updatePostalAddr}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group className="mb-3">
                  <Row>
                    <Form.Label className="is_name_div">Vehicle </Form.Label>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="classLicense"
                        name="classLicense"
                        placeholder="Vehicle class license"
                        value={vehicleClass}
                        onChange={updateVehicleClass}
                        required
                      />
                    </Col>
                    <Col md={6}>
                      {" "}
                      <FormControl
                        className="input_div"
                        type="text"
                        id="license"
                        name="license"
                        placeholder="Vehicle license number"
                        value={vehicalLicenseNo}
                        onChange={updateVehicalLicenseNo}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Passport Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="passport"
                        name="passport"
                        placeholder="Passport Number"
                        value={passportNumber}
                        onChange={updatePassportNumber}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Health Card Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="health"
                        name="health"
                        placeholder="Health Card Number"
                        value={healthCardNo}
                        onChange={updateHealthCardNo}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Housing Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="house"
                        name="house"
                        placeholder="Housing Number"
                        value={housingNo}
                        onChange={updateHousingNo}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Citizenship ID Number
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="text"
                        id="citizen"
                        name="citizen"
                        placeholder="Citizenship ID Number"
                        value={citizenshipIDNo}
                        onChange={updateCitizenshipIDNo}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Document
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>
                <Form.Group>
                  <Row>
                    <Form.Label className="is_name_div">
                      Upload Selfie
                    </Form.Label>
                    <Col md={6}>
                      <FormControl
                        className="input_div"
                        type="file"
                        name="file"
                        onChange={selectSelfieImage}
                        required
                      />
                    </Col>
                  </Row>
                </Form.Group>

                <ButtonSubmit className="btn-div" type="submit">
                  Submit
                </ButtonSubmit>
                <ButtonReset
                  onClick={btnReset}
                  className="btn_div"
                  type="reset"
                >
                  Reset
                </ButtonReset>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Newkyc;

const Heading = styled.div`
  margin-top: 4.5rem;
  margin-bottom: 1.5rem;
  font-size: 2.5rem;
  text-align: center;
  margin-left: -10rem;
  @media screen and (min-width: 769px) and (max-width: 991px) {
    font-size: 2rem;
    margin-left: -3.2rem;
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    font-size: 2rem;
    margin-left: 2rem;
  }
  @media screen and (min-width: 375px) and (max-width: 768px) {
    font-size: 1.5rem;
    margin-left: -11rem;
  }
  @media screen and (width: 768px) {
    margin-left: -5rem;
  }
`;
const FormControl = styled(Form.Control)`
  background: #f1f2f3;
  border-radius: 10px;
`;
const ButtonSubmit = styled(Button)`
  background: #1240c2;
  border: none;
  border-radius: 10px;
  :hover {
    background: #1240c2;
  }
`;
const ButtonReset = styled(Button)`
  background: #f1f2f3;
  border: none;
  border-radius: 10px;
  color: #b0b7c3;
  :hover {
    background: #f1f2f3;
    color: #b0b7c3;
  }
`;

const Out = styled.div`
  display: flex;
  justify-content: right;
  margin-top: 4rem;
  margin-left: 43rem;

  > button {
    width: 10rem;
    height: 3rem;
    border: none;
    border-radius: 1rem;
    font-size: 1.5rem;
    background-color: red;
    color: #fff;
    text-align: center;
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    margin-bottom: 2rem;
    margin-left: -2rem;
  }
`;
