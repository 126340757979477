import axios from 'axios';
import {Auth} from 'aws-amplify';
import {BASE_URL, PRESIGNED_API_URL} from '../config'

let urls = BASE_URL;


const GetToken = async()=>{
    const pairwiseLogin = localStorage.getItem('login')
    if(pairwiseLogin !== null){
        return pairwiseLogin
    }else{
      const token = await Auth.currentSession();
      console.log('kycAuth', token)
      return token.idToken.jwtToken

    }
   
        
}
const PostCall= async(path,body)=> {
  const token = await GetToken()
  return await (axios.post(`${urls}${path}`, body ,{ 'headers':  { 'Authorization': token }})
  .then(res => res.data)
  .catch(err=> err));
}

// const PostRnote= async(path,body)=> {
//   const token = await GetToken()
//   return await (axios.post('https://retoolapi.dev/uEs4zc/data/uEs4zc/data')
//   .then(res => res.data)
//   .catch(err=> err));
// }

const GetCall = async(path) => {
  console.log("getts call......")
  const token = await GetToken()
  console.log("getts token......", token)

  return await (axios.get(`${urls}${path}`, { 'headers':  { 'Authorization': token }})
  .then(res => res.data)
  .catch(err=> err));
}

const GetImage = async(bucket, path) => {
  // console.log(img_url)
  let checkUrl = `${PRESIGNED_API_URL}?bucket=${bucket}&path=${path}`;
  const token = await GetToken()

  return await (axios.get
    (`${checkUrl}`, 
    { 'headers':  { 'Authorization': token }})
  .then(res => res.data)
  .catch(err=> err));
}

export {
    PostCall,
    GetCall,
    GetImage,
    // PostRnote
}