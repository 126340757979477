import React, { useState, useContext, useEffect } from "react";
import GeneralForm from "./BTCForms/GeneralForm";
import BTCVCAvailableForms from "./BTCVCAvailableForms";
import BTCCertificate from "./BTCCertificate";
import VCContext from "./VCContext";
import BTCForm from "./BTCForms/BTCForm";
import { getDID } from "../services/vcService";
function BTCCreate(props) {
  const [page, setPage] = useState(1);
  const [myDid, setMyDid] = useState(null);
  
  useEffect(() => {
    let res = getDID();
    res.then(function (v) {
      if (Array.isArray(v)) {
        setMyDid( v[0].did );
      } else {
        setMyDid(v);
      }
    });
  }, []);


  function submitForm(inputs) {
    console.log(inputs);
    setPage(1);
  }

  function back() {
    setPage(1);
  }

  function activePage() {
    if (page === 1) {
      return (
        <React.Fragment>
          <BTCForm
            back={back}
            myDid={myDid}
            submitForm={submitForm}
          />
        </React.Fragment>
      );
    }
    else if (page === 2) {
      return (
        <React.Fragment>
          {/* <BTCCertificate data={form} /> */}
          {/* <div className="row justify-content-center mt-3">
            <div className="col-3">
              <button
                type="button"
                className="btn btn-outline-primary btn-lg btn-block"
                onClick={() ` => editForm()}
              >
                Edit
              </button>
            </div>
            <div className="col-3">
              <button
                type="button"
                className="btn btn-outline-success btn-lg btn-block"
                onClick={() => mintForm()}
              >
                Submit
              </button>
            </div>
          </div> */}
        </React.Fragment>
      );
    }
  }

  return (
    <div className="container-fluid card" style={{ marginTop: "150px" }}>
      <div className="card-body">
        <div className="row justify-content-between bg-light">
          <div className="col">
            <h4 className="py-2">Create New Instrument</h4>
          </div>
          <div className="col-2 py-2">
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={() => props.changePath("/btc")}
            >
              Back To Main
            </button>
          </div>
        </div>
        {activePage()}
        {/* <BTCForm /> */}
      </div>
    </div>
  );
}

export default BTCCreate;
