import React, { useState, useRef } from "react";
import styled from "styled-components";
import { Row, Col, Form } from "react-bootstrap";
import { Auth } from "aws-amplify";
import Swal from "sweetalert2";
import "../styles/Signup_style.css";

const OtpScreen = (props) => {
  //     return (
  //         <h1 style={{ marginTop: 200, marginLeft: 300}}> Otp screen</h1>
  //     )
  // }
  // export default OtpScreen;
  const [user, setUser] = useState("");
  const [otp, setOtp] = useState("");
  const otpInput = useRef(null);
  const userInput = useRef(null);

  const updateUser = (event) => {
    setUser(event.target.value);
  };
  const updateOtp = (event) => {
    setOtp(event.target.value);
  };
  console.log(otp);
  console.log(user);

  async function confirmSignUp() {
    try {
      const { res } = await Auth.confirmSignUp(user, otp);
      console.log(" Code confirmed");
      console.log(res);

      Swal.fire(
        "Success!",
        "Registration Successful... Now you can Login with your Credentials",
        "success"
      );
      props.history.push("/usersignin");
    } catch (error) {
      if (error.code === "ExpiredCodeException") {
        return Swal.fire(
          "Oops...",
          " Email and OTP combination is not correct",
          "error"
        );
      }
      console.log(
        " Verification code does not match. Please enter a valid verification code.",
        error.code
      );
    }
  }

  const submitOtp = () => {
    if (!otp) {
      return null;
    } else if (otp.length !== 6) {
      return (
        <h2 style={{ color: "red", fontSize: 15, marginTop: 5 }}>
          {" "}
          Enter 6 digits OTP recieved on your Email or Mobile Number
        </h2>
      );
    } else {
      return (
        <h3 style={{ color: "green", fontSize: 15, marginTop: 5 }}>
          Valid OTP
        </h3>
      );
    }
  };

  return (
    <>
      <Input>
        <Row>
          <Col>
            <div className="signup-div">
              <Heading>
                {" "}
                <h1> OTP Verification</h1>{" "}
              </Heading>
              <Form.Group className="mb-3 form-div">
                <Row>
                  <Form.Label className="is_name_div">
                    Email / Mobile Number
                  </Form.Label>
                  <Col md={12}>
                    <Form.Control
                      className="input_form_div"
                      type="text"
                      id="email"
                      ref={userInput}
                      name="email"
                      autoComplete="off"
                      value={user}
                      onChange={updateUser}
                      placeholder="Enter Email or Mobile Number"
                      required
                    />
                  </Col>
                </Row>
              </Form.Group>

              <Form.Group className="mb-3 form-div">
                <Row>
                  <Form.Label className="is_name_div">
                    One Time Password
                  </Form.Label>
                  <Col md={12}>
                    <Form.Control
                      className="number_div"
                      type="number"
                      id="otp"
                      ref={otpInput}
                      name="otp"
                      value={otp}
                      autoComplete="off"
                      onChange={updateOtp}
                      placeholder="Enter OTP"
                      required
                    />
                    {submitOtp()}
                  </Col>
                </Row>
              </Form.Group>
              <Button1
                type="submit"
                onClick={confirmSignUp}
                disabled={!otp || !user}
              >
                {" "}
                Submit
              </Button1>
            </div>
          </Col>
        </Row>
      </Input>
    </>
  );
};

export default OtpScreen;
const Input = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 35rem;
  height: 28rem;
  border: 1px solid black;
  border-radius: 1rem;
  margin-top: 10rem;
  padding: 3rem;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  @media screen and (min-width: 360px) and (max-width: 767px) {
    margin-top: 15rem;
    width: 22rem;
  }
  @media screen and (min-width: 992px) and (max-width: 1200px) {
    margin-left: 10rem;
  }
  @media screen and (min-width: 1201px) {
    margin-left: 10rem;
  }
`;

const Heading = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: -4rem;
  margin-bottom: 2rem;
  > h1 {
    text-align: center;
  }
`;

const Button1 = styled.div`
  width: 8rem;
  height: 2.5rem;
  background-color: #1240c2;
  font-size: 1.5rem;
  color: #fff;
  text-align: center;
  border-radius: 1rem;
  margin-top: 2rem;
`;
