function toHex(str) {
  const bytes = 32;

  let hex = Buffer.from(str, "utf8").toString("hex");

  const padding = 2 * (bytes - str.length);

  const paddedHex = hex.padEnd(hex.length + padding, "0");

  return `0x${paddedHex}`;
}

export { toHex };
