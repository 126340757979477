import React, { useState, useEffect } from "react";

import { Card, Dropdown } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Button } from "semantic-ui-react";
import styled from "styled-components";
// import { Col, Row } from "reactstrap";
import { GetCall } from "../services/KycService";
import "../styles/Sidemenu_style.css";
// import MobileClass from "./MobileClass";
import search_icon from "../Assets/icons/Search.png";

function PublicTableGrid(props) {
  const [selected_kyc_status, setSelected_kyc_status] = useState("S");
  const [selected_button, setSelected_button] = useState("S");
  const [R_Button, setR_Button] = useState(false);
  const [S_Button, setS_Button] = useState(true);
  const [D_Button, setD_Button] = useState(false);
  const [A_Button, setA_Button] = useState(false);
  const [query, setQuery] = useState("");
  const [searchString, setSearchString] = useState([]);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      const res = await GetCall("getKycList");
      if (res.message) {
        // setUsers(res.message.filter(item => item.hasOwnProperty('kyc_status')));
        // setIsLoading(false);
        const publicDidUsers = res.message.filter(
          (item) =>
            item.hasOwnProperty("kyc_status") &&
            item.hasOwnProperty("kycType") &&
            item.kycType === "PublicDid"
        );
        setUsers(publicDidUsers);
        setIsLoading(false);
        // const { users } = this.state;                            ?`

        const submittedUsers = publicDidUsers.filter((e) =>
          e.kyc_status.includes("S")
        );
        console.log(submittedUsers);
        setFilteredUsers(submittedUsers);
      } else {
        throw new Error("API is not returning the data");
      }
    }
    fetchData();
  }, []);

  function changeButtonstatus(data) {
    if (data === "A") {
      setA_Button(true);
      setS_Button(false);
      setD_Button(false);
      setR_Button(false);
      setSelected_button("A");

      const acceptedUsers = users.filter((e) => e.kyc_status === "A");
      console.log(acceptedUsers);
      setFilteredUsers(acceptedUsers);
    } else if (data === "D") {
      setA_Button(false);
      setS_Button(false);
      setD_Button(true);
      setR_Button(false);
      setSelected_button("D");
      const declinedUsers = users.filter((e) => e.kyc_status === "D");
      console.log(declinedUsers);

      setFilteredUsers(declinedUsers);
    } else if (data === "S") {
      setA_Button(false);
      setS_Button(true);
      setD_Button(false);
      setR_Button(false);
      setSelected_button("S");
      const submittedUsers = users.filter((e) => e.kyc_status === "S");
      console.log(submittedUsers);

      setFilteredUsers(submittedUsers);
    } else {
      setA_Button(false);
      setS_Button(false);
      setD_Button(false);
      setR_Button(true);
      setSelected_button("R");
      const reviewUsers = users.filter((e) => e.kyc_status === "R");
      console.log(reviewUsers);
      setFilteredUsers(reviewUsers);
    }
  }
  const handleInputChange = (event) => {
    setQuery(event.target.value);
  };
  const renderTable = () => {
    let searchString = query;
    return filteredUsers
      .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
      .map((user, index) => {
        if (
          (user.hasOwnProperty("first_name") &&
            user.first_name
              .toLowerCase()
              .includes(searchString.toLowerCase())) ||
          String(user.email).includes(searchString)
        ) {
          const dateString = user.timestamp;
          const indexNumber = index + 1;
          const formatDate = (dateString) => {
            const options = {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            };
            return new Date(dateString).toLocaleDateString(undefined, options);
          };

          const decodeString = (string) => {
            if (string) {
              if (string.indexOf("&amp;") !== -1) {
                let newS = string.replace(/&amp;/g, "&");
                if (newS.indexOf("&amp;") !== -1) {
                  decodeString(newS);
                } else {
                  return newS;
                }
              } else {
                return string;
              }
            }
          };
          const date = formatDate(dateString);
          return (
            <>
              <tbody style={{ textAlign: "center" }}>
                <tr>
                  <td>{indexNumber}</td>
                  <td>
                    {user.entityName
                      ? decodeString(user.entityName)
                      : decodeString(user.entityName)}{" "}
                    {/* {user.last_name ? user.last_name : user.last_name} */}
                  </td>
                  <td>
                    {" "}
                    {user.email
                      ? user.email
                      : user.phoneNumber || user.phone_number}{" "}
                  </td>
                  <td>{date}</td>
                  <td>
                    <button
                      className="view_btn"
                      style={btn_details}
                      onClick={() => userClick(user.uid)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </>
          );
        } else if (searchString === "") {
          const dateString = user.timestamp;
          const indexNumber = index + 1;
          const formatDate = (dateString) => {
            const options = {
              year: "numeric",
              month: "numeric",
              day: "numeric",
            };
            return new Date(dateString).toLocaleDateString(undefined, options);
          };
          const decodeString = (string) => {
            if (string) {
              if (string.indexOf("&amp;") !== -1) {
                let newS = string.replace(/&amp;/g, "&");
                if (newS.indexOf("&amp;") !== -1) {
                  decodeString(newS);
                } else {
                  return newS;
                }
              } else {
                return string;
              }
            }
          };

          const date = formatDate(dateString);
          return (
            <>
              <tbody style={{ textAlign: "center" }}>
                <tr>
                  <td>{indexNumber}</td>
                  <td>
                    {user.entityName
                      ? decodeString(user.entityName)
                      : decodeString(user.entityName)}{" "}
                    {user.last_name
                      ? decodeString(user.last_name)
                      : decodeString(user.last_name)}
                  </td>
                  <td>
                    {" "}
                    {user.email
                      ? user.email
                      : user.phoneNumber || user.phone_number}{" "}
                  </td>
                  <td>{date}</td>
                  <td>
                    <button
                      className="view_btn"
                      style={btn_details}
                      onClick={() => userClick(user.uid)}
                    >
                      View
                    </button>
                  </td>
                </tr>
              </tbody>
            </>
          );
        }
      });
  };

  const userClick = (data) => {
    props.history.push("/kyc_public_details", { uid: data });
  };
  return (
    <>
      <ButtonST className="btn_st_div">
        <Button
          className="btn_status_div4"
          style={btn_status}
          toggle
          active={S_Button}
          onClick={() => changeButtonstatus("S")}
        >
          SUBMITTED
        </Button>
        <Button
          className="btn_status_div4"
          style={btn_status}
          toggle
          active={D_Button}
          onClick={() => changeButtonstatus("D")}
        >
          DECLINED
        </Button>
        <Button
          className="btn_status_div4"
          style={btn_status}
          toggle
          active={A_Button}
          onClick={() => changeButtonstatus("A")}
        >
          ACCEPTED
        </Button>
      </ButtonST>

      <br />
      <br />
      <div className="nav_input-name">
        <input
          className="input_search"
          type="text"
          placeholder="Search Given Name or Email"
          value={query}
          onChange={handleInputChange}
        />
        <img src={search_icon} alt="search" className="search_icon_img" />{" "}
      </div>
      <Wrapper>
        <Table striped bordered variant="light" responsive="sm">
          <thead>
            <tr style={{ textAlign: "center" }}>
              <th>#</th>
              <th>Entity Name</th>
              <th>Email/ Mobile Number</th>
              <th>Date</th>
              <th>Look Up</th>
            </tr>
          </thead>
          {renderTable()}
        </Table>
      </Wrapper>
    </>
  );
}
export default PublicTableGrid;

const cardTable = {
  borderRadius: "15px",
  marginTop: "5px",
  border: "none",
  outline: "none",
};
const Wrapper = styled.div`
  width: 100%;
  background-color: #dce3e8;
  border-radius: 8px;
  padding: 30px;
  margin-bottom: 50px;
  @media screen and (min-width: 360px) and (max-width: 767px) {
    font-size: 1rem;
  }
`;
const dropdown_menu = {
  background: "#F2F2F2",
  height: "43px",
  borderRadius: "15px",
  border: "none",
  outline: "none",
};
const btn_status = {
  width: "151px",
  height: "43px",
  // padding: "15px 25px 15px 25px",
  background: "#699EFF",
  borderRadius: "8px",
  color: "white",
};

const btn_status_active = {
  width: "151px",
  height: "43px",
  background: "#699EFF",
  borderRadius: "8px",
  color: "white",
};

const btn_details = {
  background: "#0D9E35",
  borderRadius: "8px",
  width: "100px",
  color: "#fff",
  fontSize: "15px",
  fontWeight: "600",
  border: "none",
  outline: "none",
  padding: "5px",
};
const ButtonST = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media screen and (min-width: 360px) and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: center !important;
  }
`;
