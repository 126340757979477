import Auth from "@aws-amplify/auth";
import React, { useEffect, useState } from "react";
import { login as LogsIn } from "../services/loginService.js";
import { toast } from "react-toastify";

const MFALogin = (props) => {
  const email = props.location.state.email;
  const password = props.location.state.password;
  const [challengeName, setChallengeName] = useState("");
  const [cognitoUser, setCognitoUser] = useState();
  const [val, setVal] = useState("");
  const [mfaType, setMfaType] = useState("");
  const [disp, setDisp] = useState("d-none")
  useEffect(() => {
    handleSubmit();
  }, []);
  console.log("usr............", challengeName);
  const handleSubmit = () => {
    LogsIn(email, password)
      .then((user) => {
        console.log("user.........", user);
        setCognitoUser(user);
        setChallengeName(user.challengeName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const mfaSelection = async (mfaType) => {
    const callbacks = {
      onSuccess: (session) => console.log(session),
      onFailure: (err) => {
        console.log(err);
        // Alert.alert("MFA Error");
      },
      mfaRequired: (challengeName, challengeParameters) => {
        console.log(
          "Callback challenge is...",
          challengeName,
          challengeParameters
        );
      },
      totpRequired: (challengeName, challengeParameters) => {
        console.log(challengeName, challengeParameters);
      },
    };
    // console.log('User object is.......', user);
    // Sending OTP based on MFA type...
    cognitoUser.sendMFASelectionAnswer(mfaType, callbacks);
    // console.log('New User Obj....', user);
  };
  const navigateToMFAVeri = async (mfaType) => {
    setDisp("")
    await mfaSelection(mfaType);

    setMfaType(mfaType);
  };
  const submitOTP = () => {
    try {
      cognitoUser.getCachedDeviceKeyAndPassword();

      Auth.confirmSignIn(
        cognitoUser, // Return object from Auth.signIn()
        val, // Confirmation code
        mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      )
        .then(async (user) => {
          // props.history.push("/");
          // window.location = "/";
          if(user.signInUserSession.idToken.payload.hasOwnProperty('cognito:groups')){
              if (user.signInUserSession.idToken.payload["cognito:groups"].includes("kyc_admins")){
                window.location = "/";
                props.history.push("/");
              } else {
                toast.error(
                  "The combination of email and password is wrong. Please try again using a valid email address and password."
                );
              }
            } else {
              toast.error(
                "The combination of email and password is wrong. Please try again using a valid email address and password."
              );
            }
        })
        .catch((err) => toast.error(
          "Please enter correct OTP"
        ));
    } catch (error) {
      console.log(
        " Verification code does not match. Please enter a valid verification code.",
        error
      );
      // showAlert('Please enter correct OTP');
    }
  };
  return (
    <div className = "container-fluid p-0 vh-100 bg-secondary row">
      <div className="card bg-light rounded-3 col-6 m-auto px-0" style = {{height: "500px"}}>
        <div className = "card-header">
          <h2 className = "mt-5 text-center">2 Factor Authentication</h2>
        </div>
        <div className="row mt-5">
          <div className="col-6 mx-auto">
            <button
              onClick={() => navigateToMFAVeri("SMS_MFA")}
              className="btn btn-outline-primary btn-lg btn-block"
            >
              OTP Authentication
            </button>
          </div>
        </div>
        <div className = "row mt-5">
          <div className="col-6 mx-auto">
            <button
              onClick={() => navigateToMFAVeri("SOFTWARE_TOKEN_MFA")}
              className="btn btn-outline-primary btn-lg btn-block"
            >
              Google Authenticator
            </button>
          </div>
        </div>
        <div className = {disp === "d-none" ? "row d-none" : "row mt-5"}>
          <div className = "col-6 m-auto">
            <input
              className = "form-control"
              type="text"
              placeholder="Please Enter OTP"
              onChange={(e) => setVal(e.target.value)}
            />
            <button className = "mt-3 btn btn-outline-success" onClick={submitOTP}>Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MFALogin;
