import React, {useState} from 'react';
import { Form, Field } from "react-final-form";
import { connection, did } from "mui-metablockchain-sdk";
import {netstat} from "../../config";

function CreateVCForm(props) {
  const [str, setStr] = useState("");
  const [newIssuer, setNewIssuer] = useState("");

	const resolve = async (data) => {
		let provider = await connection.buildConnection(netstat);
    	let convertName = await did.resolveDIDToAccount({identifier: data.vcDID}, provider);
		//console.log(convertName);
		if (!convertName) {
			return { vcDID : "Not a valid did"};
		}
	}

	const onSubmit = async (values) => {
		props.submitForm({...values, issuerList: str});
	}

	let formData = {
		Amount: "",
		vcDID: "",
    issuerList: ""
	};

	return (
		<div className = "card my-5 bg-light">
			<div className = "card-body">
				<div className = "row justify-content-center mb-3 text-center">
					<div className = "col-6">
						<h4>Create a VC For Minting and Slashing</h4>
					</div>
				</div>
				<Form
					onSubmit = {onSubmit}
					initialValues = {{...formData}}
					validate = {(values) => {
						const errors = {};
						if (!values.Amount) {
							errors.Amount = "Amount cannot be blank";
						} else if(isNaN(values.Amount)) {
							errors.Amount = "Must be a number";
						}
            if (!values.vcDID) {
              errors.vcDID = "DID cannot be blank"
            }
            if (!values.issuerList) {
              errors.issuerList = "Issuer List cannot be blank"
            }
						return Object.keys(errors).length ? errors : resolve(values);
					}}
					render = {({handleSubmit, form, submitting, pristine, values }) => (
						<form onSubmit = {handleSubmit}>
              <Field name = "issuerList">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "issuerList" className = "form-label mb-2">List Of Issuers</label>
										<h6>{str.length > 0 ? str : "No Issuers Chosen"}</h6>
                    {meta.error && <div className = "invalid-feedback">{meta.error}</div>}
                    <div className = "input-group">
                      <span className="input-group-text" id="did-prefix">did:yidindji</span>
                      <input type = "text" className = "form-control" id="didIssuers" onChange = {(e) => {
                          input.onChange(e);
                          setNewIssuer(e.target.value);
                        }}
                      placeholder = "Enter did without did:yididnji prefix to add to list" 
                      />
                    </div>
                    <button className = "btn btn-primary mt-2" onClick = {(e) => {
                      e.preventDefault();
                      if(newIssuer.length > 0) {
                        setStr(str + newIssuer + ",");
                        document.getElementById("didIssuers").value = ''
                      }
                    }}>Add DID</button>	
									</div>
								)}
							</Field>
						    <Field name = "vcDID">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "vcDID" className = "form-label">Enter DID</label>
										<input {...input} 
											type = "text" 
											id="vcDID"
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
                                            placeholder = "Enter DID to which this VC will be issued"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}		
									</div>
								)}
							</Field>
							<Field name = "Amount">
								{({ input, meta }) => (
									<div className = "mb-3">
										<label htmlFor = "Amount" className = "form-label">Enter Amount</label>
										<input {...input} 
											type = "text" 
											className = {meta.touched ? (meta.error ? "form-control is-invalid" : "form-control is-valid") : "form-control"} 
											id="Amount" 
											placeholder = "Enter the amount in numbers"
										/>
										{meta.error && meta.touched && <div className = "invalid-feedback">{meta.error}</div>}
										{!meta.error && meta.touched && <div className = "valid-feedback">Looks Good!</div>}	
									</div>
								)}
							</Field>
							{/* <div className = "mb-3">
								<div className = "accordion" id = "sponsorAccordion">
									<div className = "accordion-item">
										<h5 className = "accordion-header" id = "approverHeader">
											<button 
												className = "accordion-button" 
												type = "button" 
												data-bs-toggle = "collapse"
												data-bs-target = "#favApprovers"
											>
												Select Required Approvers (Optional)
											</button>
										</h5>
										<div id = "favApprovers" className = "accordion-collapse collapse" aria-labelledby = "approverHeader">
											<div className = "accordion-body">
												{getFavApprovers()}
											</div>
										</div>
									</div>
								</div>
							</div> */}
							<div className = "row justify-content-center">
								<div className = "col-3 mt-3">
									<button 
										className = "btn btn-outline-primary btn-block"
										type = "submit"
										disabled = {submitting}
									>
										Proceed
									</button>
								</div>
							</div>
						</form>
					)}
				/>		
			</div>
		</div>
	)
}

export default CreateVCForm;